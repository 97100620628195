import { Question } from '../../../../@types/app-types';
import SoundWave from '../../../../animation/SoundWave';
import AudioRecorder from '../../../../components/AudioRecorder';
import './RecordResponseModal.css';

const RecordResponseModal: React.FC<{
  showModal: (dismiss: boolean) => void;
  question: Question;
  handleSend: (url: string) => void;
  sending?: boolean;
}> = ({ showModal, question, handleSend, sending }) => {
  //Send

  return (
    <div>
      {sending ? (
        <div className="items center flex w-full flex-col justify-center p-8">
          <p className="text-center text-lg font-semibold text-gray-400">
            Sending to {question.email}
          </p>
          <div className="flex h-[200px] justify-center">
            <SoundWave />
          </div>
          <p className="text-center text-gray-400">
            Do not navigate from this page until the upload has finished.
            Thanks!
          </p>
        </div>
      ) : (
        <AudioRecorder handleSend={handleSend} />
      )}
    </div>
  );
};

export default RecordResponseModal;
