import { createSlice } from '@reduxjs/toolkit';
import { CallState, LoadingState, Question } from '../@types/app-types';

export const questionState = createSlice({
  name: 'questions',
  initialState: {
    allQuestions: [],
    questions: [],
    answers: [],
    questionsLoading: LoadingState.INIT,
    getQuestion: LoadingState.INIT,
    currentQuestion: null,
  } as {
    allQuestions: Question[];
    questions: Question[];
    answers: Question[];
    questionsLoading: CallState;
    getQuestion: CallState;
    currentQuestion: Question | null;
  },
  reducers: {
    initQuestions: (state) => {
      state.questionsLoading = LoadingState.LOADING;
    },
    initQuestionsSuccess: (state, { payload }) => {
      state.questionsLoading = LoadingState.LOADED;
      state.questions = payload.filter(
        (question: Question) => !question.mediaLocation,
      );
      state.answers = payload.filter(
        (question: Question) => question.mediaLocation,
      );
      state.allQuestions = payload;
    },
    initQuestionFailure: (state, { payload }) => {
      state.questionsLoading = { error: payload };
    },
    setQuestions: (state, { payload }) => {
      state.questions = payload;
    },
    setQuestionsLoading: (state, { payload }) => {
      state.questionsLoading = payload;
    },
    getQuestion: (state) => {
      state.getQuestion = LoadingState.LOADING;
    },
    getQuestionSuccess: (state, { payload }) => {
      if (state.allQuestions.length > 0) {
        state.allQuestions = state.allQuestions.map((question) =>
          question.id === payload.id ? payload : question,
        );
        state.questions = state.questions.map((question) =>
          question.id === payload.id ? payload : question,
        );
        state.answers = state.answers.map((question) =>
          question.id === payload.id ? payload : question,
        );
      } else {
        state.allQuestions = [payload];
        if (payload.mediaLocation) {
          state.answers = [payload];
        } else {
          state.questions = [payload];
        }
      }
      state.getQuestion = LoadingState.LOADED;
      state.currentQuestion = payload;
    },
  },
});

export const {
  setQuestions,
  setQuestionsLoading,
  initQuestions,
  initQuestionsSuccess,
  initQuestionFailure,
  getQuestion,
  getQuestionSuccess,
} = questionState.actions;

export default questionState.reducer;
