import {
  IonPage,
  IonContent,
  IonGrid,
  useIonToast,
  IonSpinner,
} from '@ionic/react';
import { AnimatePresence, motion } from 'framer-motion';
import { mail } from 'ionicons/icons';
import { useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { fadeIn } from '../../../animation';
import Button from '../../../components/Button';
import InputField from '../../../components/InputField';
import PageTitle from '../../../components/PageTitle';
import BrandedHeader from '../../components/BrandedHeader';
import { useAuth } from '../../services/auth.services';

const ForgotPassword: React.FC<{}> = () => {
  //Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
  });
  const [present, dismiss] = useIonToast();
  const [loading, setLoading] = useState(false);
  const { sendResetPasswordEmail } = useAuth();

  const handleForgotPassword = async ({ email }: { email: string }) => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      await sendResetPasswordEmail(email);
      present({
        message: 'Please check your email to reset your password',
        header: 'Email sent!',
        duration: 3000,
      });
    } catch (error) {
      console.error(error);
      present({
        message: 'Unable to send forgot password email',
        header: 'Error',
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const contentVariants = {
    hidden: {
      opacity: 0,
      y: 25,
      transition: {
        delay: 0.25,
        staggerChildren: 0.4,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      y: -25,
    },
  };
  return (
    <IonPage>
      <IonContent>
        <BrandedHeader />
        <IonGrid fixed>
          <AnimatePresence exitBeforeEnter>
            <motion.section
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={contentVariants}
              className="mt-28 px-8 text-center"
            >
              <motion.div
                variants={fadeIn}
                drag
                dragConstraints={{
                  top: -1,
                  left: -1,
                  right: 1,
                  bottom: 1,
                }}
              >
                <PageTitle title="Forgot Password" className="mb-8"></PageTitle>
              </motion.div>
              <form autoComplete="off">
                <motion.div variants={fadeIn}>
                  <InputField
                    label="Email"
                    name="email"
                    type="email"
                    icon={mail}
                    register={register}
                    error={errors.email as FieldError}
                  />
                </motion.div>
                <motion.div variants={fadeIn} className="mt-4">
                  <Button
                    text="Reset Password"
                    onClick={handleSubmit((data) => handleForgotPassword(data))}
                    className="mt-2 border-gray-300"
                    disabled={loading}
                    icon={
                      loading && (
                        <IonSpinner className="h-4 w-4 text-white"></IonSpinner>
                      )
                    }
                  />
                </motion.div>
              </form>
            </motion.section>
          </AnimatePresence>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
