// templates/component/PageWrapper.tsx

import {
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import React, { forwardRef, ReactNode } from 'react';

interface PageWrapperProps {
  title: string;
  children: ReactNode;
  onAction?: () => void;
  actionIcon?: string;
  onHeaderAction?: () => void;
  headerActionText?: string;
  headerActionIcon?: string;
  ref?: any;
  contentClassName?: string;
}

const PageWrapper: React.FC<PageWrapperProps> = forwardRef(
  (
    {
      title,
      children,
      onAction,
      actionIcon,
      contentClassName,
      onHeaderAction,
      headerActionText,
      headerActionIcon,
    },
    ref,
  ) => {
    // hooks

    // state

    // refs

    // effects

    // methods
    return (
      <IonPage ref={ref}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              {onAction && (
                <IonButton onClick={onAction}>
                  <IonIcon icon={actionIcon} />
                </IonButton>
              )}
            </IonButtons>
            <IonTitle>{title}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className={contentClassName}>
          <IonHeader collapse="condense">
            <IonToolbar className="flex items-center">
              <IonTitle size="large">{title}</IonTitle>
              {onHeaderAction && (
                <div slot="end">
                  <IonButton onClick={onHeaderAction} fill="clear">
                    {headerActionText}
                    {headerActionIcon && (
                      <IonIcon icon={headerActionIcon} slot="start" />
                    )}
                  </IonButton>
                </div>
              )}
            </IonToolbar>
          </IonHeader>
          <IonGrid fixed>{children}</IonGrid>
        </IonContent>
      </IonPage>
    );
  },
);

export default PageWrapper;
