import { useEffect } from 'react';
import { supabase } from '../../helpers/supabase/supabaseClient';
import {
  ApiError,
  AuthChangeEvent,
  Session,
  User as SupabaseUser,
} from '@supabase/supabase-js';
import { useDispatch } from 'react-redux';
import * as Actions from '../store/auth-state';
import { useHistory } from 'react-router-dom';
import { useProfileService } from '../../services/profile.service';
import { useQuestionService } from '../../services/question.service';
import useAnalyticsService from '../../services/analytics.service';

export const useAuth = () => {
  const history = useHistory();
  const { trackRecommendedEvent } = useAnalyticsService();

  //TODO: Handle the case that a pre-existing user is trying to sign up with same credentials.
  //https://supabase.com/docs/reference/javascript/auth-signup
  const dispatch = useDispatch();
  const signUp = async (email: string, password: string) => {
    try {
      dispatch(Actions.signup());
      const { user, session, error } = await supabase.auth.signUp({
        email,
        password,
      });
      if (user) {
        dispatch(Actions.signupSuccess(user));
        // change in app state checks profile, if no profile, navigates to onboarding
      }
      if (error) {
        throw error;
      }
      trackRecommendedEvent('sign_up', { method: 'email' });
      return user;
    } catch (error) {
      dispatch(Actions.signupFailure(error));
      throw error;
    }
  };

  const login = async (email: string, password: string) => {
    dispatch(Actions.login());
    try {
      const { user, session, error } = await supabase.auth.signIn({
        email,
        password,
      });
      if (error) throw error;
      if (user && session) {
        dispatch(Actions.loginSuccess(user));
      } else {
        dispatch(Actions.loginSuccess(null));
      }
    } catch (error) {
      dispatch(Actions.loginError(error));
      throw error;
    }
  };
  const updateAuth = async (value: { email?: string; password?: string }) => {
    try {
      const { user, error } = await supabase.auth.update(value);
      if (error) throw error;
      return user;
    } catch (error) {
      throw error;
    }
  };

  // login and signup with google function the same.  /google-redirect handles whether it is a new or existing user
  async function logInWithGoogle() {
    dispatch(Actions.setAuthLoading(true));
    try {
      const { user, session, error } = await supabase.auth.signIn(
        {
          provider: 'google',
        },
        {
          redirectTo: `${window.location.origin}/google-redirect`,
        },
      );
      if (user) {
        dispatch(Actions.setUser(user));
      }
      if (error) {
        throw error;
      }
    } catch (error) {
      throw error;
    } finally {
      dispatch(Actions.setAuthLoading(false));
    }
  }
  const signOut = async (redirect = true): Promise<void> => {
    dispatch(Actions.logout(null));
    try {
      await supabase.auth.signOut();
      dispatch(Actions.logoutSuccess(null));
      redirect && history.push('/get-started');
    } catch (error) {
      throw error;
    }
  };

  const sendResetPasswordEmail = (
    email: string,
  ): Promise<{
    data: {} | null;
    error: ApiError | null;
  }> => {
    return supabase.auth.api.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
  };

  return {
    signUp,
    login,
    signOut,
    logInWithGoogle,
    updateAuth,
    sendResetPasswordEmail,
  };
};

export const useAuthState = () => {
  const dispatch = useDispatch();
  const profile = useProfileService();

  useEffect(() => {
    dispatch(Actions.initUser());
    const user = supabase.auth.user();
    const session = supabase.auth.session();
    if (user && session) {
      dispatch(Actions.initUserSuccess(user));
      profile.getUserProfile(user.id);
    } else {
      dispatch(Actions.initUserSuccess(null));
    }
  }, []);

  useEffect(() => {
    const { data, error } = supabase.auth.onAuthStateChange(
      async (event: AuthChangeEvent, session: Session | null) => {
        dispatch(Actions.setAuthEvent(event));
        if (session?.user) {
          dispatch(Actions.setUser(session.user));
          profile.getUserProfile(session.user.id);
        } else {
          dispatch(Actions.setUser(null));
        }
      },
    );
    if (data) {
      return data.unsubscribe;
    }
  }, []);
};
