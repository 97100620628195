import { IonContent, IonGrid, IonPage, useIonToast } from '@ionic/react';
import InputField from '../../../components/InputField';
import PageTitle from '../../../components/PageTitle';
import BrandedHeader from '../../components/BrandedHeader';
import Button from '../../../components/Button';
import GoogleSignInButton from '../../components/GoogleSignIn';
import { lockClosed, mail } from 'ionicons/icons';
import { motion, AnimatePresence } from 'framer-motion';
import { fadeIn, slideUpFromBottom } from '../../../animation';
import { FieldError, useForm } from 'react-hook-form';
import { useAuth } from '../../services/auth.services';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useEffect } from 'react';
import { ErrorState, LoadingState } from '../../../@types/app-types';

interface FormProps {
  email: string;
  password: string;
}

export default function LogIn() {
  //Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const auth = useAuth();
  const history = useHistory();
  const { loginLoading } = useSelector((state: RootState) => state.auth);
  const [present, dismiss] = useIonToast();

  useEffect(() => {
    if (loginLoading === LoadingState.LOADED) {
      history.push('/');
    }
    if ((loginLoading as ErrorState).error) {
      present({
        message: 'Incorrect email or password',
        duration: 3000,
        color: 'danger',
      });
    }
  }, [loginLoading]);

  const handleLogin = async (data: FormProps) => {
    auth.login(data.email, data.password);
  };

  const handleLogInWithGoogle = async () => {
    try {
      await auth.logInWithGoogle();
    } catch (error) {
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: `There was a problem logging in with Google`,
        duration: 2000,
      });
      console.error(error);
    }
  };
  //    The exit properties below won't apply because we are using Ionic's router animations.
  //    Should we decide to not use the Ionic Router, here is a setup tutorial for Framer Motion router
  //    https://youtu.be/Imyi2V7WgGU?t=100
  const contentVariants = {
    hidden: {
      opacity: 0,
      y: 25,
      transition: {
        delay: 0.25,
        staggerChildren: 0.4,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      y: -25,
    },
  };

  return (
    <IonPage>
      <IonContent>
        <BrandedHeader />
        <IonGrid fixed>
          <AnimatePresence exitBeforeEnter>
            <motion.section
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={contentVariants}
              className="mt-28 px-8 text-center"
            >
              <motion.div
                variants={fadeIn}
                drag
                dragConstraints={{
                  top: -1,
                  left: -1,
                  right: 1,
                  bottom: 1,
                }}
              >
                <PageTitle title="Log in" className="mb-8"></PageTitle>
              </motion.div>

              <form autoComplete="off">
                <motion.div variants={fadeIn}>
                  <InputField
                    label="Email"
                    name="email"
                    type="email"
                    icon={mail}
                    placeholder="youremail@gmail.com"
                    autoFocus={true}
                    register={register}
                    hasPattern={{
                      value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                      message: 'Please use a valid email address',
                    }}
                    error={errors.email as FieldError}
                  />
                </motion.div>
                <motion.div variants={fadeIn}>
                  <InputField
                    label="Password"
                    name="password"
                    type="password"
                    icon={lockClosed}
                    placeholder="password"
                    register={register}
                    isRequired="You must have a password"
                  />
                </motion.div>
              </form>
              <motion.div variants={fadeIn} className="mt-4">
                <Button
                  text="Log in"
                  onClick={handleSubmit((data) => handleLogin(data))}
                  className="mt-2 border-gray-300"
                />
              </motion.div>
              <motion.p variants={fadeIn} className="my-4 text-sm">
                or...
              </motion.p>
              <motion.div variants={fadeIn}>
                <GoogleSignInButton
                  text="Log in"
                  onClick={() => handleLogInWithGoogle()}
                />
              </motion.div>
              <motion.div variants={fadeIn}>
                <p className="mt-4">
                  First time here?{' '}
                  <Link
                    to="/sign-up"
                    className="no font-semibold text-gray-900 underline"
                  >
                    Sign up
                  </Link>
                </p>
              </motion.div>
              <motion.div variants={fadeIn}>
                <p className="mt-4">
                  <Link
                    to="/forgot-password"
                    className="no font-semibold text-gray-900 underline"
                  >
                    Forgot Password
                  </Link>
                </p>
              </motion.div>
            </motion.section>
          </AnimatePresence>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}
