import ReactGA from 'react-ga4';
//See this documentation for more information on recommended events:
//https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up

const useAnalyticsService = () => {
  const trackCustomEvent = (
    category = 'category',
    action = 'action',
    label = 'label',
  ) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  const trackRecommendedEvent = (type = 'type', options = {}) => {
    ReactGA.gtag('event', type, options);
  };
  return { trackCustomEvent, trackRecommendedEvent };
};

export default useAnalyticsService;
