import { motion } from 'framer-motion';
import { Question } from '../../../../@types/app-types';
import { fadeIn } from '../../../../animation';

const ResponseContent: React.FC<{ question: Question }> = ({ question }) => {
  return (
    <div className="my-8 mx-auto w-full rounded-xl bg-gray-100 p-6">
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={fadeIn}
      >
        <p className="text-xl font-bold text-black">{question.subject}</p>
        <p className="mt-4 text-gray-700">
          From: {question.name || question.email}
        </p>
        <hr className="my-4 w-full bg-gray-200" />
      </motion.div>
      <p>{question.question}</p>
    </div>
  );
};

export default ResponseContent;
