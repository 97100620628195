import { definitions, Answer } from '../@types';
import { camelize } from '../helpers/api-transformations';
import { supabase } from '../helpers/supabase/supabaseClient';
import { apiSlice, providesList } from './api-slice';

const answersApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getMyAnswers: build.query({
      queryFn: async (profileId) => {
        const { data, error } = await supabase
          .from<definitions['answers']>('answers')
          .select(
            '*, guests!id(*), profiles!id(*), questions!inner(*, profiles:questions_profile_id_fkey!inner(*))',
          )
          .eq('profile_id', profileId);
        if (error) return { error };
        const formatted = data && (data.map((d) => camelize(d)) as Answer[]);
        return { data: formatted };
      },
      providesTags: (result) => providesList(result, 'MyAnswers'),
    }),
    answerAskedQuestion: build.mutation({
      queryFn: async ({ profileId, mediaLocation, questionId }) => {
        const { data, error } = await supabase
          .from<definitions['answers']>('answers')
          .insert({
            question_id: questionId,
            media_location: mediaLocation,
            profile_id: profileId,
          })
          .single();
        if (error) return { error };
        return { data: camelize(data) };
      },
    }),
  }),
});

export const { useGetMyAnswersQuery, useAnswerAskedQuestionMutation } =
  answersApiSlice;
