import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Play } from './assets/play.svg';
import { ReactComponent as Pause } from './assets/pause.svg';
import { isIOS, isSafari } from 'react-device-detect';

import IOSAudioPlayer from './IOSAudioPlayer';
import { motion } from 'framer-motion';
import DownloadButton from '../../../../components/DownloadButton';

const AudioPlayer: React.FC<{ url: string; singleLine?: boolean }> = ({
  url,
  singleLine,
}) => {
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [totalTime, setTotalTime] = useState('0:00');
  const [isPlaying, setIsPlaying] = useState(false);
  const [iosDefault, setIOSDefault] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const seekSlider = useRef<HTMLInputElement | null>(null);
  // have to use ref here because state isn't accessible in listener
  const playingRef = useRef<boolean>(false);
  useEffect(() => {
    if (url) {
      if (audioRef.current) {
        audioRef.current.src = url;
      }
      setupListeners();
    } else {
      audioRef.current = new Audio();
    }
  }, [url]);

  useEffect(() => {
    // this is the current best way to tell that the audio has stopped playing
    if (currentTime === '0:00') {
      setIsPlaying(false);
    }
  }, [currentTime]);

  const setupListeners = () => {
    if (!audioRef.current) return;
    audioRef.current.addEventListener('loadedmetadata', () => {
      // if it's infinity, we need the IOSAudioPlayer to display
      if (!isFinite(audioRef.current.duration)) {
        setIOSDefault(true);
        return;
      }
      setTotalTime(calculateTime(audioRef.current.duration));
      setDuration(Math.floor(audioRef.current.duration));
      if (seekSlider.current) {
        seekSlider.current.value = '0';
      }
    });

    audioRef.current.addEventListener('timeupdate', () => {
      setCurrentTime(calculateTime(audioRef.current.currentTime));
      if (seekSlider.current) {
        seekSlider.current.value = Math.floor(
          audioRef.current.currentTime,
        ).toString();
      }
    });

    audioRef.current.addEventListener('ended', () => {
      playingRef.current = false;
      if (seekSlider.current) {
        seekSlider.current.value = '0';
        setCurrentTime('0:00');
      }
    });

    audioRef.current.addEventListener('loadeddata', () => {
      if (playingRef.current === true) {
        audioRef.current.play();
      }
    });
  };

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  };

  const play = () => {
    playingRef.current = true;
    setIsPlaying(true);
    if (audioRef.current.readyState < 2) {
      audioRef.current.load();
    } else {
      audioRef.current.play();
    }
    if (seekSlider.current) {
      audioRef.current.currentTime = Number(seekSlider.current.value);
    }
  };

  const pause = () => {
    audioRef.current.pause();
    playingRef.current = false;
    setIsPlaying(false);
  };
  return (
    <div className="w-full">
      {isSafari || isIOS || iosDefault ? (
        <IOSAudioPlayer url={url} singleLine={singleLine} />
      ) : (
        <>
          <audio ref={audioRef} preload="metadata" />
          {singleLine ? (
            <div className="flex items-center gap-4 rounded-full bg-gray-200 py-2 px-4">
              {isPlaying ? (
                <motion.button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => pause()}
                  aria-label="Pause"
                >
                  <Pause className="h-4 w-4" />
                </motion.button>
              ) : (
                <motion.button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => {
                    play();
                  }}
                  aria-label="Play"
                >
                  <Play className="h-4 w-4" />
                </motion.button>
              )}
              <div className="flex w-full grow justify-center">
                <span>{currentTime}</span>/<span>{totalTime}</span>
              </div>
            </div>
          ) : (
            <>
              <div className="flex w-full py-4">
                <input
                  ref={seekSlider}
                  type="range"
                  min={0}
                  max={duration}
                  className="progress"
                  onInput={(e) =>
                    seekSlider.current &&
                    setCurrentTime(
                      calculateTime(parseInt(seekSlider.current.value)),
                    )
                  }
                  onChange={(e) => {
                    if (seekSlider.current) {
                      audioRef.current.currentTime = parseInt(
                        seekSlider.current.value,
                      );
                    }
                  }}
                />
              </div>
              <div className="flex w-full justify-between">
                <span>{currentTime}</span>
                <span>{totalTime}</span>
              </div>
              <div className="flex justify-center">
                {isPlaying ? (
                  <button
                    type="button"
                    className="pause flex items-center justify-center rounded-full p-6 shadow-lg"
                    onClick={() => pause()}
                    aria-label="Pause"
                  >
                    <Pause className="h-6 w-6" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="play flex items-center justify-center rounded-full p-6 shadow-lg"
                    onClick={() => {
                      play();
                    }}
                    aria-label="Play"
                  >
                    <Play className="h-6 w-6" />
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AudioPlayer;
