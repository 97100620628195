import { useEffect, useMemo, useState } from 'react';
import { useProfileService } from '../../services/profile.service';
import TextInput from '../TextInput';
import { useIonToast } from '@ionic/react';
import { debounce } from 'lodash';
import { generateUniqueUrl } from '../../helpers/misc';

const QuestionUrl: React.FC<{
  firstName?: string;
  lastName?: string;
  questionUrl: string;
  handleQuestionUrl: (url: string) => void;
  handleUrlValid: (isValid: boolean) => void;
  icon?: any;
  validateUrl?: boolean;
}> = ({
  firstName,
  lastName,
  questionUrl,
  handleQuestionUrl,
  handleUrlValid,
  icon,
  validateUrl = true,
}) => {
  const { checkQuestionUrl } = useProfileService();
  const [present, dismiss] = useIonToast();
  const [focused, setFocused] = useState(false);
  const [defaultUrl, setDefaultUrl] = useState('');

  useEffect(() => {
    if (!questionUrl) {
      generateDefaultUrl();
    }
    if (questionUrl) {
      setDefaultUrl(questionUrl);
      if (validateUrl) urlUnique(questionUrl);
    }
  }, []);

  useEffect(() => {
    if (focused) {
      debounceUrlQuery();
    }
  }, [questionUrl]);

  const debounceUrlQuery = useMemo(() => {
    return debounce(() => urlUnique(questionUrl), 500);
  }, [questionUrl]);

  useEffect(() => {
    return () => {
      debounceUrlQuery.cancel();
    };
  });

  const generateDefaultUrl = async () => {
    // join first and last, replace any spaces with dashes.
    let urlToCheck = `${firstName}-${lastName}`
      .split(' ')
      .join('-')
      .toLowerCase();
    const url = await generateUniqueUrl(urlToCheck, checkQuestionUrl);
    handleUrlValid(true);
    handleQuestionUrl(url);
  };

  const urlUnique = async (url: string) => {
    try {
      if (questionUrl === defaultUrl) return;
      const isUnique = await checkQuestionUrl(url);
      if (isUnique) {
        handleUrlValid(true);
        return;
      }
      handleUrlValid(false);
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: `This url already exists, please choose a new one`,
        color: 'danger',
        duration: 5000,
      });
    } catch (error) {
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: `Unable to check url`,
        duration: 5000,
      });
      console.error(error);
    }
  };

  const changeQuestionUrl = (url: string) => {
    const regex = new RegExp(/^[\w-_]*$/);
    const isValid = regex.test(url);
    if (isValid) {
      handleQuestionUrl(url.toLocaleLowerCase());
    }
  };

  return (
    <div className="flex w-full flex-col">
      <div className="mb-6">
        <TextInput
          label="Question Url"
          name="questionUrl"
          type="text"
          placeholder="Enter question url"
          onChange={(e) =>
            changeQuestionUrl(e.target.value.toLocaleLowerCase())
          }
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => setFocused(false)}
          value={questionUrl}
          icon={icon}
        />
      </div>
    </div>
  );
};

export default QuestionUrl;
