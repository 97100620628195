import { IonLabel, IonModal, IonSegment, IonSegmentButton } from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { Answer } from '../../@types';
import MultiComponentSlider from '../../components/MultiComponentSlider';
import PageWrapper from '../../components/PageWrapper';
import ViewMyAnswerModal from './components/ViewMyAnswerModal';
import AskedAnswers from './components/AskedAnswers';
import MyAnswers from './components/MyAnswers';
import { downloadAudio } from '../../helpers/supabase/utils';
import { useHistory } from 'react-router-dom';

const Answers = () => {
  // hooks
  const history = useHistory();
  // state
  const [selectedSegment, setSelectedSegment] = useState<number>(0);
  const [showViewAnswer, setShowViewAnswer] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<Answer>();

  // ref
  const answersRootRef = useRef<any>(null);
  const viewAnswerModalRef = useRef<any>(null);

  // effects

  // methods
  const handleAddQuestion = () => {
    history.push('/questions');
  };

  const handleViewAnswer = (answer: Answer) => {
    setSelectedAnswer(answer);
    setShowViewAnswer(true);
  };

  const handleDismissViewAnswer = () => {
    setShowViewAnswer(false);
    setSelectedAnswer(undefined);
  };

  return (
    <PageWrapper title="Answers">
      <MyAnswers
        handleAddQuestion={handleAddQuestion}
        handleViewAnswer={handleViewAnswer}
      />
      <IonModal
        isOpen={showViewAnswer}
        ref={viewAnswerModalRef}
        onDidDismiss={handleDismissViewAnswer}
        presentingElement={answersRootRef.current || undefined}
        canDismiss={true}
      >
        {selectedAnswer && (
          <ViewMyAnswerModal
            answer={selectedAnswer}
            onDismiss={handleDismissViewAnswer}
          />
        )}
      </IonModal>
    </PageWrapper>
  );
};

export default Answers;
