import { IonLabel, IonModal, IonSegment, IonSegmentButton } from '@ionic/react';

import { useRef, useState } from 'react';
import './index.css';
import AskedQuestions from './components/AskedQuestions';
import MultiComponentSlider from '../../components/MultiComponentSlider';
import PageWrapper from '../../components/PageWrapper';
import MyQuestions from './components/MyQuestions';
import AddMyQuestionModal from './components/AddMyQuestionModal';
import ViewMyQuestionModal from './components/ViewMyQuestionModal';
import { Question } from '../../@types';
import { addSharp } from 'ionicons/icons';
import ViewAskedQuestionModal from './components/ViewAskedQuestionModal';

const Questions: React.FC = () => {
  // state
  const [selectedSegment, setSelectedSegment] = useState<number>(0);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [showViewQuestion, setShowViewQuestion] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Question>();
  const [showViewAskedQuestion, setShowViewAskedQuestion] = useState(false);

  // refs
  const questionsRootRef = useRef<any>(null);
  const addQuestionModalRef = useRef<any>(null);
  const viewQuestionModalRef = useRef<any>(null);
  const viewAskedQuestionModalRef = useRef<any>(null);

  // methods
  const dismissViewQuestion = () => {
    setShowViewQuestion(false);
    setSelectedQuestion(undefined);
  };

  const handleViewQuestion = (question: Question) => {
    setSelectedQuestion(question);
    setShowViewQuestion(true);
  };

  const dismissViewAskedQuestion = () => {
    setShowViewAskedQuestion(false);
    setSelectedQuestion(undefined);
  };

  const handleViewAskedQuestion = (question: Question) => {
    setSelectedQuestion(question);
    setShowViewAskedQuestion(true);
  };

  return (
    <PageWrapper
      title="Questions"
      onHeaderAction={() => setShowAddQuestion(true)}
      headerActionText="Add Question"
      headerActionIcon={addSharp}
    >
      <IonSegment
        mode="md"
        onIonChange={(e) => setSelectedSegment(parseInt(e.detail.value || '0'))}
        value={selectedSegment?.toString()}
      >
        <IonSegmentButton value="0">
          <IonLabel>My Questions</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="1">
          <IonLabel>Questions Inbox</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <MultiComponentSlider
        pages={[
          <MyQuestions
            handleViewQuestion={handleViewQuestion}
            handleAddQuestion={() => setShowAddQuestion(true)}
          />,
          <AskedQuestions handleViewAskedQuestion={handleViewAskedQuestion} />,
        ]}
        page={selectedSegment}
        direction={selectedSegment === 0 ? -1 : 1}
      />
      <IonModal
        ref={viewQuestionModalRef}
        isOpen={showViewQuestion}
        onDidDismiss={dismissViewQuestion}
        presentingElement={questionsRootRef.current || undefined}
        canDismiss={true}
      >
        {selectedQuestion && (
          <ViewMyQuestionModal
            question={selectedQuestion}
            onDismiss={dismissViewQuestion}
          />
        )}
      </IonModal>
      <IonModal
        ref={addQuestionModalRef}
        isOpen={showAddQuestion}
        canDismiss={true}
        presentingElement={questionsRootRef.current || undefined}
        onDidDismiss={() => setShowAddQuestion(false)}
      >
        <AddMyQuestionModal onDismiss={() => setShowAddQuestion(false)} />
      </IonModal>
      <IonModal
        ref={viewAskedQuestionModalRef}
        isOpen={showViewAskedQuestion}
        canDismiss={true}
        presentingElement={questionsRootRef.current || undefined}
        onDidDismiss={dismissViewAskedQuestion}
      >
        {selectedQuestion && (
          <ViewAskedQuestionModal
            question={selectedQuestion}
            onDismiss={dismissViewAskedQuestion}
          />
        )}
      </IonModal>
    </PageWrapper>
  );
};

export default Questions;
