import { supabase } from '../helpers/supabase/supabaseClient';

const useStorage = () => {
  const downloadAudioFile = async (path: string): Promise<void> => {
    try {
      const {
        data,
        error,
      }: {
        data: Blob | null;
        error: Error | null;
      } = await supabase.storage.from('audio').download(path);
      if (error) {
        throw error;
      }
      const url = URL.createObjectURL(data as Blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = path;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      throw error;
    }
  };

  return {
    downloadAudioFile,
  };
};

export default useStorage;
