import { supabase } from './supabaseClient';
import { isSafari, isMobile, isIOS } from 'react-device-detect';
/**
 * Returns a file path to the uploaded photo.
 *
 * @param {string} photo A base64 string.
 * @return {string} path to file in the 'avatars' bucket.
 */

//TODO: Debug why Supabase doesn't like the base64 file
//https://supabase.com/docs/reference/javascript/storage-from-upload

export const uploadAvatar = async (photo: string) => {
  try {
    if (photo === '' || photo === null) {
      throw new Error('You must select an image to upload.');
    }
    const file = photo;
    let blob: string | Blob = file;
    if (typeof file === 'string') {
      // uses fetchAPI to convert base64 string to blob
      const res = await fetch(file);
      blob = await res.blob();
    }
    //The file we get from the AvatarCropper is always a png
    const fileName = `${Date.now()}.png`;
    let { error: uploadError } = await supabase.storage
      .from('avatars')
      .upload(fileName, blob);
    if (uploadError) {
      throw uploadError;
    }
    return fileName;
  } catch (error) {
    throw error;
  }
};

/**
 * Takes in a file name to download from the 'avatars' bucket.
 *
 * @param {string} path The name of the file stored in profiles.avatar_media_location.
 * @return {string} url to the file to use as src.
 */
export const downloadImage = async (path: string): Promise<string> => {
  try {
    const {
      data,
      error,
    }: {
      data: Blob | null;
      error: Error | null;
    } = await supabase.storage.from('avatars').download(path);
    if (error) {
      throw error;
    }
    const url = URL.createObjectURL(data as Blob);
    return url;
  } catch (error) {
    throw error;
  }
};

/**
 * Takes in a url pointing to a blob from the recorder.
 * Parses out the blob and uploads it to Supabase
 *
 * @param {string} mediaBlobUrl — The location of the blob after recording
 * @return {mp3} — the audio file
 */
export const uploadAudio = async (mediaBlobUrl: string): Promise<string> => {
  try {
    // upload audio
    const audioBlob = await fetch(mediaBlobUrl).then((res) => res.blob());
    const fileName = `${Date.now()}.mp3`;
    if (audioBlob) {
      /**
       * this is a hacky way around the fact that we can't get the whole file to upload
       * when uploaded from safari to the audio service.  Works fine in chrome.
       */
      if (isIOS || isSafari) {
        const { data, error } = await supabase.storage
          .from('audio')
          .upload(fileName, audioBlob);
        if (error) throw error;
      } else {
        // todo - fix file upload for Dev instance.  Currently only works when pointed at prod
        const url = `${process.env.REACT_APP_API_URL}/convert/audio`;
        const formData = new FormData();
        formData.append('audio', audioBlob, fileName);
        const res = await fetch(url, {
          method: 'POST',
          body: formData,
        }).then((res) => res.json());
      }
      return fileName;
    } else {
      throw new Error('unable to upload audio');
    }
  } catch (error) {
    console.error('error in uploadAudio fn', error);
    throw error;
  }
};

/**
 * Takes in a file name to download from the 'audio' bucket.
 *
 * @param {string} path The name of the file stored in profiles.avatar_media_location.
 * @return {string} url to the file to use as src.
 */
export const downloadAudio = async (path: string) => {
  try {
    const {
      data,
      error,
    }: {
      data: Blob | null;
      error: Error | null;
    } = await supabase.storage.from('audio').download(path);
    if (error) {
      throw error;
    }
    const url = URL.createObjectURL(data as Blob);
    return url;
  } catch (error) {
    throw error;
  }
};
