import { IonModal, IonContent } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSwiper } from 'swiper/react';
import Button from '../../../../components/Button';
import PageTitle from '../../../../components/PageTitle';
import PhotoUpload from '../../PhotoUploadOnboarding/PhotoUpload';
import AvatarCropper from '../AvatarCropper';

const OnboardingPhoto: React.FC<{
  photo?: string | undefined | null;
  setPhoto: (image: string) => void;
  nextRoute: string;
}> = ({ photo, setPhoto, nextRoute }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const handleNewImage = (image: string) => {
    setPhoto(image);
    setModalOpen(false);
  };
  return (
    <div className="flex flex-col items-center justify-center">
      <PageTitle
        title="Add a profile photo"
        className="text-center text-black"
      />
      <PhotoUpload
        handleClick={() => setModalOpen(true)}
        photo={photo || '/defaultAvatar.png'}
      />
      <div className="w-4/5 pt-8">
        <Button
          text="Continue"
          disabled={!photo}
          onClick={() => history.push(nextRoute)}
        />
      </div>
      <IonModal
        isOpen={modalOpen}
        breakpoints={[0.1, 0.5, 1]}
        initialBreakpoint={1}
        onDidDismiss={() => setModalOpen(false)}
      >
        <IonContent className="flex flex-col justify-center">
          <AvatarCropper
            initialImage={'/defaultAvatar.png'}
            handleNewImage={handleNewImage}
          />
        </IonContent>
      </IonModal>
    </div>
  );
};

export default OnboardingPhoto;
