// templates/component/ViewAskedQuestionModal.tsx

import { IonModal, useIonToast } from '@ionic/react';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Question } from '../../../../@types';
import Button from '../../../../components/Button';
import DownloadButton from '../../../../components/DownloadButton';
import FullQuestionCard from '../../../../components/FullQuestionCard';
import ModalWrapper from '../../../../components/ModalWrapper';
import { downloadAudio, uploadAudio } from '../../../../helpers/supabase/utils';
import useAnalyticsService from '../../../../services/analytics.service';
import { useQuestionService } from '../../../../services/question.service';
import { useSendgridService } from '../../../../services/sendgrid.service';
import { useAnswerAskedQuestionMutation } from '../../../../store/answers-api-slice';
import { useAppSelector } from '../../../../store/store';
import AudioPlayer from '../../../Response/components/Player';
import RecordResponseModal from '../../../Response/components/RecordResponseModal';

interface ViewAskedQuestionModalProps {
  question: Question;
  onDismiss: () => void;
}

const ViewAskedQuestionModal: FC<ViewAskedQuestionModalProps> = ({
  question,
  onDismiss,
}) => {
  // hooks
  const { profile } = useAppSelector((state) => state.auth);
  const { getQuestion, updateQuestion } = useQuestionService();
  const { sendAnswerEmail, sendGenericEmail } = useSendgridService();
  const [present, dismiss] = useIonToast();
  const history = useHistory();
  const { trackCustomEvent } = useAnalyticsService();
  const [answerAskedQuestion, { isLoading, isError, isSuccess, error }] =
    useAnswerAskedQuestionMutation();

  // state
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [audioFile, setAudioFile] = useState<any>(null);

  // refs

  // effects
  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        handleCreateAnswerSuccess();
      }
      if (isError) {
        present({
          message: (error as any).message || 'Please try again',
          header: 'Error Saving Audio',
          duration: 2500,
        });
      }
    }
  }, [isLoading, isError, isSuccess]);

  useEffect(() => {
    if (question.answers?.length) {
      const answer = question.answers[0];
      if (answer.mediaLocation) {
        populateAudio(answer.mediaLocation);
      }
    }
  }, [question.answers]);

  // methods
  const handleSend = async (url: string) => {
    setSending(true);
    const filePath = await uploadAudio(url);
    answerAskedQuestion({
      profileId: profile?.id,
      questionId: question.id,
      mediaLocation: filePath,
    });
  };

  const handleCreateAnswerSuccess = async () => {
    try {
      await sendAnswerEmail(question);
      await sendGenericEmail(
        'New answer has been posted',
        `${profile?.email} has answered a question for ${question.email}.`,
      );
      getQuestion(question.id);
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: 'Answer Saved!',
        duration: 2500,
      });
      trackCustomEvent('question', 'answer', 'sent');
      setShowResponseModal(false);
      onDismiss();
      history.push('/questions');
    } catch (error: any) {
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: error.message || 'Please try again',
        header: 'Error Saving Audio',
        duration: 2500,
      });
      setSending(false);
      throw error;
    }
  };

  const populateAudio = async (filePath: string) => {
    const audio = await downloadAudio(filePath);
    setAudioFile(audio);
  };

  return (
    <ModalWrapper
      title={
        question.answers?.length ? 'Listen to Answer' : 'Respond to Question'
      }
      endSlot={
        audioFile &&
        question?.answers?.length && (
          <div className="mr-4">
            <DownloadButton path={question.answers[0].mediaLocation} iconOnly />
          </div>
        )
      }
      onDismiss={onDismiss}
    >
      <div className="p-4">
        <div className="flex flex-col gap-6">
          <FullQuestionCard
            subject={question.subject}
            question={question.question}
          />
          {question.answers?.length ? (
            audioFile && <AudioPlayer url={audioFile} />
          ) : (
            <Button
              text="Record your response"
              onClick={() => setShowResponseModal(true)}
            />
          )}
        </div>
      </div>
      <IonModal
        isOpen={showResponseModal}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.5, 0.75]}
        onDidDismiss={() => setShowResponseModal(false)}
        showBackdrop={true}
      >
        <RecordResponseModal
          showModal={setShowResponseModal}
          question={question}
          sending={sending}
          handleSend={handleSend}
        />
      </IonModal>
    </ModalWrapper>
  );
};

export default ViewAskedQuestionModal;
