import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';

export function providesList<
  R extends { id: string | number }[],
  T extends string,
>(resultsWithIds: R | undefined, tagType: T) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map(({ id }) => ({
          type: tagType,
          id: id.toString(),
        })),
      ]
    : [{ type: tagType, id: 'LIST' }];
}

export const apiSlice = createApi({
  tagTypes: ['MyQuestions', 'MyAnswers'],
  baseQuery: fakeBaseQuery(),
  endpoints: (builder) => ({}),
});

export const {} = apiSlice;
