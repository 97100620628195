import { IonContent, IonGrid, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import * as checkmark from '../../../assets/animations/782-check-mark-success.json';
import { useQuestionService } from '../../../services/question.service';
import { Profile, Question } from '../../../@types';
import { useSessionStorage } from '../../../services/session-storage.service';
import useAnalyticsService from '../../../services/analytics.service';
const Success: React.FC = () => {
  const { id, question } = useParams() as { id: string; question: string };
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile>();
  const [userQuestion, setUserQuestion] = useState<Question>();
  const { getQuestion } = useQuestionService();
  const { removeItem } = useSessionStorage();
  const { trackRecommendedEvent } = useAnalyticsService();

  useEffect(() => {
    removeItem('questionId');
    const getUserQuestion = async (id: string) => {
      try {
        const currentQuestion = await getQuestion(id);

        if (currentQuestion) {
          setUserQuestion(currentQuestion);
          setProfile(currentQuestion.expert);
        } else {
          setUserQuestion(undefined);
        }
      } catch (error) {
        console.error('unable to get question', error);
      }
    };
    if (question) {
      getUserQuestion(question);
    }
  }, [question]);
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed>
          <div className="h-screen w-full pt-[10vh]">
            <div className="m-4 flex flex-col items-center justify-center rounded-2xl border-2 border-blue-500 bg-white p-[25px] shadow-xl">
              {loading ? (
                <h2>Loading</h2>
              ) : (
                <>
                  <Lottie
                    animationData={checkmark}
                    className="mt-6"
                    loop={false}
                  />
                  <h1 className="m-6 text-center text-[24px] text-green-500">
                    Your question to {profile?.firstName} has been submitted 🎉
                  </h1>
                  <div className="pt-8">
                    <p className="text-center">
                      Create your own QuickTake account!
                    </p>
                  </div>
                  <div>
                    <a href="https://app.quicktake.io">app.quicktake.io</a>
                  </div>
                </>
              )}
            </div>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Success;
