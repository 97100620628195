export interface Answer {
  id: number;
  questionId: number;
  answer: string;
  createdAt: string;
  updatedAt: string;
  profileId: string;
  profiles?: Profile;
  guests?: Guest;
  mediaLocation: string;
  questions: Question;
}

export interface Profile {
  id: string;
  updatedAt: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  avatarMediaLocation: string;
  role: string;
  questionUrl: string;
  email?: string;
  answers?: Answer[];
  questions?: Question[];
}

export interface Guest {
  id: string;
  updatedAt: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  answers?: Answer[];
}

export interface Question {
  id: number;
  profileId: string;
  email?: string;
  mediaLocation: string;
  shareable?: boolean;
  createdAt: string;
  subject: string;
  question: string;
  name?: string;
  reminderSent?: string;
  url: string;
  profiles: Profile;
  answers?: Answer[];
}

/**
 * Combining loading state and error in order to clean up reducer and make
 * displaying loading and success/error modals easier in the app.
 * Motivated by this article: https://medium.com/angular-in-depth/ngrx-how-and-where-to-handle-loading-and-error-states-of-ajax-calls-6613a14f902d
 */
export const enum LoadingState {
  INIT = 'INIT',
  LOADED = 'LOADED',
  LOADING = 'LOADING',
}

export interface ErrorState {
  error: any;
}

export type CallState = LoadingState | ErrorState;
