import { PencilIcon } from '@heroicons/react/solid';
import { IonItem, IonThumbnail, IonLabel } from '@ionic/react';
import { format } from 'date-fns/esm';
import { useEffect, useState } from 'react';
import { Profile } from '../../../../@types/app-types';
import { downloadImage } from '../../../../helpers/supabase/utils';

const ViewProfile: React.FC<{ profile: Profile; itemClick: () => void }> = ({
  profile,
  itemClick,
}) => {
  const [photo, setPhoto] = useState<string | undefined>();
  useEffect(() => {
    const getPhotoUrl = async () => {
      try {
        const photoURL = await downloadImage(profile.avatarMediaLocation);
        setPhoto(photoURL);
      } catch (error) {
        console.error('error getting photo', error);
      }
    };
    getPhotoUrl();
  }, []);

  return (
    <div className="m-4 flex w-full items-center" onClick={() => itemClick()}>
      <img
        src={photo || ''}
        className="h-[80px] w-[80px] rounded-full"
        alt="profile"
      />
      <div className="ml-4 flex flex-col justify-center">
        <span className="text-3xl font-bold">
          {profile.firstName} {profile.lastName}
        </span>
        <span className="mt-1 font-light">
          Date Joined:{' '}
          <span className="font-bold">
            {profile.createdAt && format(new Date(profile.createdAt), 'PP')}
          </span>
        </span>
      </div>
    </div>
  );
};

export default ViewProfile;
