// templates/component/AnswerQuestionSuccess.tsx

import { IonPage, IonContent, IonGrid } from '@ionic/react';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { fadeIn } from '../../../animation';
import * as checkmark from '../../../assets/animations/782-check-mark-success.json';

interface AnswerQuestionSuccessProps {}

const AnswerQuestionSuccess: React.FC<AnswerQuestionSuccessProps> = () => {
  // hooks
  const history = useHistory();
  // state

  // refs

  // effects

  // methods
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed className="h-full min-h-full">
          <motion.div
            variants={fadeIn}
            className="flex h-[90%] flex-col items-center justify-center"
          >
            <Lottie
              animationData={checkmark}
              className="flex h-48 w-48"
              loop={false}
            />
            <h1 className="text-24px m-6 text-center text-green-500">
              Your answer has been submitted 🎉!
            </h1>
            <div className="flex justify-center pt-8">
              <span
                className="cursor-pointer text-center text-xl underline"
                onClick={() => history.push('/')}
              >
                Back to QuickTake
              </span>
            </div>
          </motion.div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AnswerQuestionSuccess;
