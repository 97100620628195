import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { setOnboarding } from '../../../store/onboarding-state';
import OnboardingPhoto from '../components/onboarding-photo';
import OnboardingContentWrapper from '../components/OnboardingContentWrapper';

const PhotoUploadOnboarding = () => {
  const { photo } = useSelector((state: RootState) => state.onboarding);
  const dispatch = useDispatch();
  const setPhoto = (value: string) => {
    dispatch(setOnboarding({ key: 'photo', value }));
  };
  return (
    <OnboardingContentWrapper title="Get Started" showBack={true} index={1}>
      <OnboardingPhoto
        setPhoto={setPhoto}
        photo={photo}
        nextRoute="/onboarding/question-url"
      />
    </OnboardingContentWrapper>
  );
};

export default PhotoUploadOnboarding;
