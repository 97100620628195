import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { definitions } from '../@types/api-types';
import { Question } from '../@types/app-types';
import { camelize, snakeize } from '../helpers/api-transformations';
import { supabase } from '../helpers/supabase/supabaseClient';
import * as Actions from '../store/question-state';
import { useSendgridService } from './sendgrid.service';

export const useQuestionService = () => {
  const dispatch = useDispatch();
  const sendgrid = useSendgridService();
  const createQuestion = async (
    question: Partial<definitions['questions']>,
  ) => {
    try {
      const { data, error } = await supabase
        .from('questions')
        .insert(question)
        .single();
      if (error) throw error;
      if (data) {
        await sendgrid.sendNewQuestionEmail(data.id);
      }
      return camelize(data);
    } catch (error) {
      throw error;
    }
  };

  const updateQuestion = async (
    questionPartial: Partial<Question>,
    id: number,
  ) => {
    try {
      const snaked: Partial<definitions['questions']> =
        snakeize(questionPartial);
      const { data, error } = await supabase
        .from<definitions['questions']>('questions')
        .update(snaked)
        .match({ id })
        .single();
      if (error) throw error;
      if (data) return camelize(data);
    } catch (error) {
      throw error;
    }
  };

  const getQuestion = async (id: string | number) => {
    try {
      dispatch(Actions.getQuestion());
      const { data, error } = await supabase
        .from('questions')
        .select('*, profile:questions_profile_id_fkey!inner(*), answers(*)')
        .eq('id', id);
      if (error) throw error;
      const question = data ? camelize(data)[0] : null;
      dispatch(Actions.getQuestionSuccess(question));
      return question;
    } catch (error) {
      throw error;
    }
  };

  const getExpertQuestions = async (id: string) => {
    try {
      dispatch(Actions.initQuestions);
      const { data, error } = await supabase
        .from('questions')
        .select('*, answers!answers_question_id_fkey(*)')
        .order('created_at', { ascending: false })
        .eq('profile_id', id)
        .not('email', 'eq', null);
      if (error) throw error;
      const questions = data ? data.map((d) => camelize(d)) : [];
      dispatch(Actions.initQuestionsSuccess(questions));
    } catch (error) {
      throw error;
    }
  };

  const checkIfQuestionUrlExists = async (url: string, profileId: string) => {
    try {
      const { data, error } = await supabase
        .from('questions')
        .select('*')
        .eq('url', url)
        .eq('profile_id', profileId);
      if (error) throw error;
      return data && data.length > 0 ? true : false;
    } catch (error) {
      throw error;
    }
  };

  return {
    createQuestion,
    updateQuestion,
    getQuestion,
    getExpertQuestions,
    checkIfQuestionUrlExists,
  };
};

export const useExpertQuestionsListener = (id?: string) => {
  const { getExpertQuestions } = useQuestionService();

  useEffect(() => {
    if (id) {
      getExpertQuestions(id);
      // handles updates to questions
      const sub = supabase
        .from(`questions:profile_id=eq.${id}`)
        .on('*', (payload) => {
          // a little heavy handed here, but trying to keep things simple
          getExpertQuestions(id);
        })
        .subscribe();
      return () => {
        supabase.removeSubscription(sub);
      };
    }
  }, [id]);
};
