// templates/component/AnswerQuestionAudioStep.tsx

import { IonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { Question } from '../../../../@types';
import { fadeIn } from '../../../../animation';
import Button from '../../../../components/Button';
import FullQuestionCard from '../../../../components/FullQuestionCard';
import RecordResponseModal from '../../../Response/components/RecordResponseModal';

interface AnswerQuestionAudioStepProps {
  question: Question;
  handleUpload: (url: string) => void;
  uploadingAudio: boolean;
}

const AnswerQuestionAudioStep: React.FC<AnswerQuestionAudioStepProps> = ({
  question,
  handleUpload,
  uploadingAudio,
}) => {
  // hooks

  // state
  const [showResponseModal, setShowResponseModal] = useState(false);
  // refs

  // effects
  useEffect(() => {
    if (!uploadingAudio && showResponseModal) {
      setShowResponseModal(false);
    }
  }, [uploadingAudio]);

  // methods

  return (
    <motion.div
      className="p-4"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <div className="pb-4">
        <span className="text-xl">
          {question.profiles.firstName} {question.profiles.lastName} would like
          you to answer the following question:
        </span>
      </div>
      <FullQuestionCard
        subject={question.subject}
        question={question.question}
      />
      <div className="pt-8">
        <Button
          text="Record your response"
          onClick={() => setShowResponseModal(true)}
        />
      </div>
      <IonModal
        isOpen={showResponseModal}
        initialBreakpoint={0.5}
        breakpoints={[0, 0.5, 0.75]}
        onDidDismiss={() => setShowResponseModal(false)}
        showBackdrop={true}
      >
        <RecordResponseModal
          showModal={setShowResponseModal}
          question={question}
          handleSend={handleUpload}
          sending={uploadingAudio}
        />
      </IonModal>
    </motion.div>
  );
};

export default AnswerQuestionAudioStep;
