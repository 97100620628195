import useStorage from '../../hooks/useStorage';
import Button from '../Button';
import { DownloadIcon } from '@heroicons/react/solid';
interface DownloadButtonProps {
  path: string;
  iconOnly?: boolean;
}

const DownloadButton = ({ path, iconOnly = false }: DownloadButtonProps) => {
  console.log('path', path);
  const { downloadAudioFile } = useStorage();

  const download = () => {
    downloadAudioFile(path);
  };
  return (
    <>
      {iconOnly ? (
        <div className="h-6 w-6">
          <DownloadIcon
            onClick={download}
            className="h-6 w-6 text-blue-700"
            role="button"
          />
        </div>
      ) : (
        <Button text="Download" onClick={download} icon={<DownloadIcon />} />
      )}
    </>
  );
};

export default DownloadButton;
