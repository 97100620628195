import {
  IonContent,
  IonGrid,
  IonPage,
  IonSpinner,
  useIonToast,
} from '@ionic/react';
import { AnimatePresence, motion } from 'framer-motion';
import { key } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { fadeIn } from '../../../animation';
import Button from '../../../components/Button';
import InputField from '../../../components/InputField';
import PageTitle from '../../../components/PageTitle';
import { useAppSelector } from '../../../store/store';
import BrandedHeader from '../../components/BrandedHeader';
import { useAuth } from '../../services/auth.services';

interface FormProps {
  password: string;
}
const ResetPassword: React.FC = () => {
  const history = useHistory();
  //Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      password: '',
    },
  });
  const [present, dismiss] = useIonToast();
  const [loading, setLoading] = useState(false);
  const { updateAuth } = useAuth();
  const authEvent = useAppSelector((state) => state.auth.authEvent);

  useEffect(() => {
    if (authEvent === 'PASSWORD_RECOVERY') {
      history.push('/reset-password');
    }
  }, [authEvent]);

  const handleChangePassword = async ({ password }: FormProps) => {
    if (loading) return;
    try {
      setLoading(true);
      await updateAuth({ password });
      present({
        message: 'Password changed.',
        header: 'Success!',
        duration: 3000,
      });
      history.push('/');
    } catch (error) {
      console.error(error);
      present({
        message: 'Unable to change password',
        header: 'Error',
        duration: 3000,
      });
      setLoading(false);
    }
  };
  const contentVariants = {
    hidden: {
      opacity: 0,
      y: 25,
      transition: {
        delay: 0.25,
        staggerChildren: 0.4,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.3,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      y: -25,
    },
  };
  return (
    <IonPage>
      <IonContent>
        <BrandedHeader />
        <IonGrid fixed>
          <AnimatePresence exitBeforeEnter>
            <motion.section
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={contentVariants}
              className="mt-28 px-8 text-center"
            >
              <motion.div
                variants={fadeIn}
                drag
                dragConstraints={{
                  top: -1,
                  left: -1,
                  right: 1,
                  bottom: 1,
                }}
              >
                <PageTitle title="Change Password" className="mb-8"></PageTitle>
              </motion.div>
              <form autoComplete="off">
                <motion.div variants={fadeIn}>
                  <InputField
                    label="New Password"
                    name="password"
                    type="password"
                    icon={key}
                    register={register}
                    error={errors.password as FieldError}
                  />
                </motion.div>
                <motion.div variants={fadeIn} className="mt-4">
                  <Button
                    text="Change Password"
                    onClick={handleSubmit((data) => handleChangePassword(data))}
                    className="mt-2 border-gray-300"
                    disabled={loading}
                    icon={
                      loading && (
                        <IonSpinner className="h-4 w-4 text-white"></IonSpinner>
                      )
                    }
                  />
                </motion.div>
              </form>
            </motion.section>
          </AnimatePresence>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
