// templates/component/MyQuestions.tsx

import { FC } from 'react';
import { useAppSelector } from '../../../../store/store';
import { useGetMyQuestionsQuery } from '../../../../store/questions-api-slice';
import { motion } from 'framer-motion';
import MyQuestionCard from '../../../../components/MyQuestionCard';
import { Profile, Question } from '../../../../@types';
import MyQuestionsZeroState from '../../../../components/MyQuestionsZeroState';

interface MyQuestionsProps {
  handleViewQuestion: (question: Question) => void;
  handleAddQuestion: () => void;
}

const MyQuestions: FC<MyQuestionsProps> = ({
  handleViewQuestion,
  handleAddQuestion,
}) => {
  // hooks
  const { profile } = useAppSelector((state) => state.auth);
  const { data, isLoading, error } = useGetMyQuestionsQuery(
    profile ? profile.id : '',
    {
      skip: !profile,
    },
  );

  // state

  // refs

  // effects

  // methods

  return (
    <motion.div className="flex flex-col gap-6 px-4 py-8">
      <div className="flex flex-col gap-4">
        <span className="text-lg font-semibold">
          Add questions here to get audio answers!
        </span>
        <div>
          <span className="text-lg">Things like:</span>
          <ul className="list-disc px-4">
            <li>Ask a question to your parents that you can keep forever.</li>
            <li>Get birthday wishes from friends for your loved one.</li>
            <li>Ask your clients for an audio testimonial.</li>
            <li>Use QuickTake to ask anything!</li>
          </ul>
        </div>
      </div>
      {isLoading ? (
        <div></div>
      ) : data?.length ? (
        data?.map((question) => (
          <MyQuestionCard
            key={question.id}
            question={question}
            handleView={(id) => handleViewQuestion(question)}
          />
        ))
      ) : (
        <MyQuestionsZeroState
          profile={profile as Profile}
          heading="you don't have any questions yet"
          subheading="Click the button below to add your first question!"
          actionText="Add Question"
          actionFn={handleAddQuestion}
        />
      )}
    </motion.div>
  );
};

export default MyQuestions;
