// templates/component/AnswerQuestionAccountTypeStep.tsx

import { motion } from 'framer-motion';
import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { fadeIn } from '../../../../animation';
import Button from '../../../../components/Button';
import InputField from '../../../../components/InputField';

interface AnswerQuestionAccountTypeStepProps {
  handleContinue: () => void;
}

const AnswerQuestionAccountTypeStep: React.FC<
  AnswerQuestionAccountTypeStepProps
> = ({ handleContinue }) => {
  // hooks
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  // state

  // refs

  // effects

  // methods
  return (
    <motion.div
      className="p-4"
      variants={fadeIn}
      initial="hidden"
      animate="visible"
    >
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-4">
          <p>
            Submit your answer as a guest, or create an account/log in so you
            can view your answer anytime.
          </p>
          <Button
            className="w-full"
            text="Continue as guest"
            onClick={() => {
              setValue('accountType', 'guest');
              handleContinue();
            }}
          />
          <div className="flex justify-center">
            <span className="text-2xl font-bold">OR</span>
          </div>
          <Button
            className="w-full"
            text="Create an account / Log in"
            onClick={() => {
              setValue('accountType', 'create');
              handleContinue();
            }}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default AnswerQuestionAccountTypeStep;
