import { differenceInBusinessDays, formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import { Question } from '../../@types/app-types';
import { fadeIn } from '../../animation';
import Button from '../Button';

const QuestionCard = ({
  question,
  handleReadMore,
}: {
  question: Question;
  handleReadMore: (id: string | number) => void;
}) => {
  return (
    <motion.div
      className="relative flex flex-col gap-4 rounded-lg bg-white py-8 px-6 shadow-lg"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      {question.answers?.length === 0 &&
        differenceInBusinessDays(new Date(), new Date(question.createdAt)) <
          2 && (
          <span className=" absolute top-3 left-6 text-sm font-bold text-blue-600">
            New
          </span>
        )}
      <span className="mb-2 text-2xl font-bold text-black">
        {question.subject}
      </span>
      <p className="text-gray-500">
        From:{' '}
        <span className="font-bold text-gray-600">
          {question.name || question.email}
        </span>
      </p>
      <p className="text-gray-500">
        Received:{' '}
        <span className="font-bold text-gray-600">
          {formatDistance(new Date(question.createdAt), new Date(), {
            addSuffix: true,
          })}
        </span>
      </p>
      {question.answers?.length ? (
        <p className="text-gray-500">
          Answered:{' '}
          <span className="font-bold text-gray-600">
            {formatDistance(
              new Date(question.answers[0].createdAt),
              new Date(),
              {
                addSuffix: true,
              },
            )}
          </span>
        </p>
      ) : (
        <></>
      )}
      <Button
        text="Read more"
        onClick={() => handleReadMore(question.id)}
      ></Button>
    </motion.div>
  );
};

export default QuestionCard;
