export const useSessionStorage = () => {
  const storage = window.sessionStorage;
  const setItem = (key: string, value: string) => {
    storage.setItem(key, value);
  };

  const getItem = (key: string) => {
    const data = storage.getItem(key);
    return data ? JSON.parse(data) : null;
  };

  const removeItem = (key: string) => {
    storage.removeItem(key);
  };

  const clearStorage = () => {
    storage.clear();
  };

  return {
    setItem,
    getItem,
    removeItem,
    clearStorage,
  };
};
