import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { fadeIn, slideDownFromTop } from '../../../animation';
const BrandedHeader = () => {
  return (
    <div className="relative flex h-36 items-center justify-center px-12 pt-12">
      {/* <img src={'./QuickTakeLogo.png'} className="z-10 w-3/4" /> */}
      <Link to="/" className="z-[1000]">
        <motion.svg
          width="299"
          height="49"
          viewBox="0 0 299 49"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="z-10"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ delay: 0.25 }}
        >
          <path
            d="M21.4559 3C19.4404 3 17.5074 3.68628 16.0822 4.90786C14.6571 6.12944 13.8564 7.78627 13.8564 9.51384V26.8841C13.8564 28.6117 14.6571 30.2685 16.0822 31.4901C17.5074 32.7117 19.4404 33.3979 21.4559 33.3979C23.4714 33.3979 25.4044 32.7117 26.8295 31.4901C28.2547 30.2685 29.0554 28.6117 29.0554 26.8841V9.51384C29.0554 7.78627 28.2547 6.12944 26.8295 4.90786C25.4044 3.68628 23.4714 3 21.4559 3V3Z"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M39.7308 21.4558V26.7031C39.7308 31.5739 37.7959 36.2452 34.3517 39.6894C30.9076 43.1336 26.2362 45.0685 21.3654 45.0685C16.4946 45.0685 11.8233 43.1336 8.37911 39.6894C4.93492 36.2452 3 31.5739 3 26.7031V21.4558"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M91.3856 25.4005C91.3856 12.2468 83.2186 4.74727 72.5582 4.74727C61.839 4.74727 53.7308 12.2468 53.7308 25.4005C53.7308 38.4952 61.839 46.0536 72.5582 46.0536C74.583 46.0536 76.5185 45.786 78.3237 45.2507C79.1079 45.0182 79.9699 45.211 80.5245 45.8122L81.0627 46.3957C81.4413 46.8062 81.9743 47.0396 82.5328 47.0396H85.3111C87.0079 47.0396 87.9339 45.0595 86.8459 43.7573L86.2641 43.0609C85.5729 42.2336 85.672 41.0146 86.3884 40.209C89.5069 36.7026 91.3856 31.6954 91.3856 25.4005ZM73.8397 32.8346C72.2371 32.8346 71.4083 34.7483 72.5047 35.9172L73.2431 36.7044C73.9032 37.4082 73.5231 38.5345 72.5582 38.5345V38.5345C66.374 38.5345 62.3494 33.9209 62.3494 25.4005C62.3494 16.88 66.374 12.2664 72.5582 12.2664C78.7228 12.2664 82.767 16.88 82.767 25.4005C82.767 29.0066 82.0344 31.92 80.7338 34.075C80.3187 34.763 79.3618 34.738 78.8809 34.0943V34.0943L78.5396 33.6374C78.162 33.1321 77.5682 32.8346 76.9374 32.8346H73.8397Z"
            fill="white"
          />
          <path
            d="M113.823 32.6644C113.842 36.7087 111.074 38.829 108.071 38.829C104.91 38.829 102.868 36.6105 102.848 33.0571V17.3487C102.848 16.2442 101.953 15.3487 100.848 15.3487H96.485C95.3804 15.3487 94.485 16.2442 94.485 17.3487V34.5491C94.5046 41.5971 98.6274 45.8966 104.694 45.8966C109.178 45.8966 112.414 43.6129 113.796 40.146C113.824 40.0744 113.893 40.0265 113.97 40.0265V40.0265C114.073 40.0265 114.157 40.1099 114.157 40.2127V43.5039C114.157 44.6085 115.052 45.5039 116.157 45.5039H120.186C121.291 45.5039 122.186 44.6085 122.186 43.5039V17.3487C122.186 16.2442 121.291 15.3487 120.186 15.3487H115.823C114.718 15.3487 113.823 16.2442 113.823 17.3487V32.6644Z"
            fill="white"
          />
          <path
            d="M125.835 43.5039C125.835 44.6085 126.731 45.5039 127.835 45.5039H132.199C133.303 45.5039 134.199 44.6085 134.199 43.5039V17.3487C134.199 16.2442 133.303 15.3487 132.199 15.3487H127.835C126.731 15.3487 125.835 16.2442 125.835 17.3487V43.5039ZM130.036 11.4615C132.53 11.4615 134.572 9.55719 134.572 7.22095C134.572 4.90434 132.53 3 130.036 3C127.563 3 125.521 4.90434 125.521 7.22095C125.521 9.55719 127.563 11.4615 130.036 11.4615Z"
            fill="white"
          />
          <path
            d="M151.482 46.0929C158.672 46.0929 163.47 42.3148 164.676 36.5489C164.912 35.4242 163.983 34.4509 162.833 34.4509H158.776C157.788 34.4509 156.983 35.1902 156.617 36.1078C155.747 38.2835 153.91 39.4768 151.58 39.4768C147.674 39.4768 145.141 36.1982 145.141 30.4263C145.141 24.733 147.693 21.474 151.58 21.474C154.037 21.474 155.786 22.7748 156.615 24.8395C156.984 25.7579 157.79 26.4999 158.78 26.4999H162.846C163.99 26.4999 164.918 25.5338 164.684 24.4137C163.475 18.6319 158.5 14.9561 151.443 14.9561C142.294 14.9561 136.64 21.2973 136.64 30.5441C136.64 39.7124 142.196 46.0929 151.482 46.0929Z"
            fill="white"
          />
          <path
            d="M167.264 43.5039C167.264 44.6085 168.159 45.5039 169.264 45.5039H173.627C174.732 45.5039 175.627 44.6085 175.627 43.5039V36.6767C175.627 36.1911 175.804 35.7222 176.124 35.3572L176.177 35.2972C177.041 34.3129 178.602 34.411 179.336 35.4955L185.516 44.625C185.888 45.1747 186.509 45.5039 187.172 45.5039H192.105C193.715 45.5039 194.665 43.6976 193.753 42.3706L185.019 29.6714C184.505 28.9243 184.559 27.925 185.149 27.2365L192.508 18.6503C193.62 17.353 192.699 15.3487 190.99 15.3487H186.667C186.078 15.3487 185.519 15.6085 185.139 16.0588L176.171 26.6849C176.113 26.7543 176.027 26.7944 175.936 26.7944V26.7944C175.765 26.7944 175.627 26.6563 175.627 26.486V7.29698C175.627 6.19241 174.732 5.29698 173.627 5.29698H169.264C168.159 5.29698 167.264 6.19241 167.264 7.29698V43.5039Z"
            fill="white"
          />
          <path
            d="M190.493 9.09626C189.493 10.4132 190.432 12.3057 192.086 12.3057H198.365C199.47 12.3057 200.365 13.2011 200.365 14.3057V43.5039C200.365 44.6085 201.261 45.5039 202.365 45.5039H206.768C207.873 45.5039 208.768 44.6085 208.768 43.5039V14.3057C208.768 13.2011 209.663 12.3057 210.768 12.3057H216.032C217.3 12.3057 218.494 11.704 219.248 10.684L220.873 8.48614C221.85 7.16602 220.907 5.29698 219.265 5.29698H195.363C194.112 5.29698 192.933 5.88198 192.177 6.87808L190.493 9.09626Z"
            fill="white"
          />
          <path
            d="M222.823 46.0733C227.238 46.0733 230.111 44.1658 231.596 41.4007C231.622 41.3526 231.672 41.3223 231.726 41.3223V41.3223C231.807 41.3223 231.873 41.388 231.873 41.4691V43.5039C231.873 44.6085 232.769 45.5039 233.873 45.5039H237.805C238.909 45.5039 239.805 44.6085 239.805 43.5039V25.1649C239.805 17.9794 233.719 14.9561 227.004 14.9561C220.78 14.9561 216.391 17.5209 214.555 21.7466C214.075 22.8516 214.933 23.9947 216.134 24.0922L219.969 24.4037C220.92 24.481 221.753 23.8361 222.319 23.0676C223.213 21.8544 224.761 21.0617 226.965 21.0617C229.812 21.0617 231.441 22.4949 231.441 24.9685V25.0863C231.441 27.0299 229.38 27.2852 224.138 27.7956C218.17 28.3453 212.81 30.3478 212.81 37.0817C212.81 43.0499 217.07 46.0733 222.823 46.0733ZM225.218 40.3014C222.646 40.3014 220.801 39.1038 220.801 36.8068C220.801 34.451 222.744 33.2926 225.689 32.8804C226.478 32.7701 227.483 32.6268 228.452 32.4457C229.796 32.1943 231.5 33.3579 231.5 34.7258V34.7258C231.5 37.8866 228.889 40.3014 225.218 40.3014Z"
            fill="white"
          />
          <path
            d="M243.252 43.5039C243.252 44.6085 244.148 45.5039 245.252 45.5039H249.616C250.72 45.5039 251.616 44.6085 251.616 43.5039V36.6767C251.616 36.1911 251.793 35.7222 252.113 35.3572L252.166 35.2972C253.03 34.3129 254.591 34.411 255.325 35.4955L261.505 44.625C261.877 45.1747 262.497 45.5039 263.161 45.5039H268.093C269.704 45.5039 270.654 43.6976 269.741 42.3706L261.008 29.6714C260.494 28.9243 260.547 27.925 261.137 27.2365L268.497 18.6503C269.609 17.353 268.687 15.3487 266.978 15.3487H262.656C262.066 15.3487 261.507 15.6085 261.127 16.0588L252.16 26.6849C252.101 26.7543 252.015 26.7944 251.924 26.7944V26.7944C251.754 26.7944 251.616 26.6563 251.616 26.4861V7.29698C251.616 6.19241 250.72 5.29698 249.616 5.29698H245.252C244.148 5.29698 243.252 6.19241 243.252 7.29698V43.5039Z"
            fill="white"
          />
          <path
            d="M284.89 46.0929C291.324 46.0929 295.948 43.3912 297.854 39.0583C298.343 37.9444 297.468 36.7941 296.254 36.7139L292.312 36.4538C291.427 36.3954 290.644 36.9581 290.111 37.6674C289.031 39.1064 287.224 39.8498 285.027 39.8498C280.846 39.8498 278.195 37.0817 278.195 32.5859V32.5859C278.195 32.575 278.204 32.5662 278.215 32.5662H296.731C297.835 32.5662 298.731 31.6708 298.731 30.5662V30.2693C298.731 20.0212 292.527 14.9561 284.556 14.9561C275.683 14.9561 269.93 21.258 269.93 30.5638C269.93 40.1247 275.604 46.0929 284.89 46.0929ZM280.301 27.3833C279.156 27.3833 278.216 26.401 278.617 25.3284C279.518 22.9143 281.763 21.1991 284.694 21.1991C287.614 21.1991 289.811 22.8749 290.57 25.3995C290.889 26.4614 289.956 27.3833 288.847 27.3833H280.301Z"
            fill="white"
          />
        </motion.svg>
      </Link>
      <motion.svg
        width="100%"
        viewBox="0 0 375 211"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0 right-0 left-0 z-[-1000]"
        initial="hidden"
        animate="visible"
        variants={slideDownFromTop}
        transition={{
          type: 'spring',
          mass: 0.4,
          damping: 8,
          duration: 0.5,
        }}
      >
        <g filter="url(#filter0_i_335_430)">
          <path
            d="M0 0H375V195.314C375 195.314 346.051 211.996 187.5 210.953C28.9489 209.91 0 195.314 0 195.314V0Z"
            fill="url(#paint0_linear_335_430)"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_335_430"
            x="0"
            y="-10"
            width="100%"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="-10" />
            <feGaussianBlur stdDeviation="7.5" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.307971 0 0 0 0 0.625 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_335_430"
            />
          </filter>
          <linearGradient
            id="paint0_linear_335_430"
            x1="106.234"
            y1="266.566"
            x2="227.944"
            y2="-117.778"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#0747D3" />
            <stop offset="1" stopColor="#79dbff" />
          </linearGradient>
        </defs>
      </motion.svg>
    </div>
  );
};

export default BrandedHeader;
