// templates/component/QuestionReviewStep.tsx

import React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import MyQuestionUrl from '../../../../../components/MyQuestionUrl';
import FullQuestionCard from '../../../../../components/FullQuestionCard';

interface QuestionReviewStepProps {}

const QuestionReviewStep: React.FC<QuestionReviewStepProps> = () => {
  // hooks
  const { getValues } = useFormContext();

  // state

  // refs

  // effects

  // methods
  return (
    <div>
      <h2 className="font-bold">Does everything look right?</h2>
      <div className="my-6">
        <FullQuestionCard
          subject={getValues('subject')}
          question={getValues('question')}
        />
      </div>
      <div className="pb-6">
        <MyQuestionUrl
          questionUrl={getValues('formattedUrl')}
          showCopy={true}
        />
      </div>
    </div>
  );
};

export default QuestionReviewStep;
