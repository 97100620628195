const Review: React.FC<{
  firstName: string;
  lastName: string;
  photoUrl: string;
}> = ({ firstName, lastName, photoUrl }) => {
  return (
    <div className="flex w-full flex-col items-center rounded-2xl border-2 border-blue-500 bg-white p-8 text-black shadow-xl">
      <div className="mb-6 h-24 w-24">
        <img
          src={photoUrl}
          className="rounded-full ring-4 ring-blue-500 ring-offset-4"
          alt="profile"
        />
      </div>
      <h2 className="text-3xl font-semibold">
        {firstName} {lastName}
      </h2>
    </div>
  );
};

export default Review;
