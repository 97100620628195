import { useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import OnboardingContentWrapper from '../components/OnboardingContentWrapper';
import QuestionUrl from '../../../components/question-url';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { setOnboarding } from '../../../store/onboarding-state';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';

const QuestionUrlOnboarding = () => {
  const { firstName, lastName, questionUrl } = useSelector(
    (state: RootState) => state.onboarding,
  );
  const [urlValid, setUrlValid] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const setQuestionUrl = (value: string) => {
    dispatch(setOnboarding({ key: 'questionUrl', value }));
  };

  return (
    <OnboardingContentWrapper title="Get Started" showBack={true} index={3}>
      <PageTitle
        title="Choose Your Unique Question URL"
        className="text-center text-black"
      />
      <PageTitle
        title="This is where your audience will submit questions to you"
        className="p-4 text-center text-xl text-black"
      />
      <div className="flex flex-col items-center justify-center">
        <div className="w-4/5">
          <QuestionUrl
            handleQuestionUrl={(url) => setQuestionUrl(url)}
            handleUrlValid={(isValid) => setUrlValid(isValid)}
            questionUrl={questionUrl}
            firstName={firstName}
            lastName={lastName}
          />
        </div>
        <div className="flex w-4/5 justify-center pt-6">
          <span className="text-lg text-gray-900">
            Don’t worry, you can change this later!
          </span>
        </div>
        <div className="w-4/5 pt-8">
          <Button
            text="Continue"
            onClick={() => history.push('/onboarding/review')}
            disabled={!questionUrl || !urlValid}
          />
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default QuestionUrlOnboarding;
