// templates/component/MyAnswers.tsx

import React from 'react';
import { Answer, Profile } from '../../../../@types';
import MyQuestionCard from '../../../../components/MyQuestionCard';
import MyQuestionsZeroState from '../../../../components/MyQuestionsZeroState';
import QuestionCard from '../../../../components/question-card';
import { useGetMyAnswersQuery } from '../../../../store/answers-api-slice';
import { useAppSelector } from '../../../../store/store';

interface MyAnswersProps {
  handleAddQuestion: () => void;
  handleViewAnswer: (answer: Answer) => void;
}

const MyAnswers: React.FC<MyAnswersProps> = ({
  handleAddQuestion,
  handleViewAnswer,
}) => {
  // hooks
  const { profile } = useAppSelector((state) => state.auth);
  const { data, isLoading } = useGetMyAnswersQuery((profile as Profile).id, {
    skip: !profile,
  });

  // state

  // refs

  // effects

  // methods
  return (
    <div className="flex flex-col gap-4 p-4">
      {isLoading ? (
        <div></div>
      ) : data?.length ? (
        data.map((answer) => (
          <MyQuestionCard
            key={answer.id}
            question={answer.questions}
            handleView={(id) => handleViewAnswer(answer)}
            answer={answer}
          />
        ))
      ) : (
        <MyQuestionsZeroState
          profile={profile as Profile}
          heading="you don't any answers yet"
          subheading="Click the button below to add your first question!"
          actionText="Navigate to Questions"
          actionFn={handleAddQuestion}
        />
      )}
    </div>
  );
};

export default MyAnswers;
