import { definitions, Question, Answer } from '../@types';
import { camelize } from '../helpers/api-transformations';
import { generateUniqueUrl } from '../helpers/misc';
import { supabase } from '../helpers/supabase/supabaseClient';
import { sendQuestionAnswerEmail } from '../services/sendgrid.service';
import { apiSlice, providesList } from './api-slice';

const questionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyQuestions: builder.query({
      queryFn: async (profileId: string) => {
        const { data, error } = await supabase
          .from<definitions['questions']>('questions')
          .select('*, questions_profile_id_fkey!inner(*)')
          .eq('profile_id', profileId)
          .neq('url', 'null');
        if (error) return { error };
        const formatted = data && (data.map((d) => camelize(d)) as Question[]);
        return { data: formatted };
      },
      providesTags: (result) => providesList(result, 'MyQuestions'),
    }),
    createQuestion: builder.mutation({
      queryFn: async (body: Partial<definitions['questions']>) => {
        const { data, error } = await supabase
          .from<definitions['questions']>('questions')
          .insert(body);
        if (error) return { error };
        // should only return one question;
        const formatted =
          data && data.length > 0 && (camelize(data)[0] as Question);
        return { data: formatted };
      },
      invalidatesTags: ['MyQuestions'],
    }),
    getQuestionToAnswer: builder.query({
      queryFn: async ({ expertUrl, questionUrl }) => {
        const { data, error } = await supabase
          .from('questions')
          .select('*, profiles:questions_profile_id_fkey!inner(*)')
          .eq('profiles.question_url', expertUrl)
          .eq('url', questionUrl)
          .single();
        if (error) return { error };
        const formatted = data && (camelize(data) as Question);
        return { data: formatted };
      },
    }),
    answerQuestionAsGuest: builder.mutation({
      queryFn: async (body: {
        guest: Partial<definitions['guests']>;
        answer: Partial<definitions['answers']>;
      }) => {
        const { data: guest, error: guestError } = await supabase
          .from<definitions['guests']>('guests')
          .insert(body.guest)
          .single();
        if (guestError) return { error: guestError };
        const { data: answer, error: answerError } = await supabase
          .from<definitions['answers']>('answers')
          .insert({
            ...body.answer,
            guest_id: guest.id,
          })
          .single();

        if (answerError) return { error: answerError };
        await sendQuestionAnswerEmail(answer.question_id);
        return { data: { guest, answer } };
      },
    }),
    answerQuestionWithAccount: builder.mutation({
      queryFn: async (body: {
        account: {
          email: string;
          password: string;
          first_name?: string;
          last_name?: string;
        };
        answer: Partial<definitions['answers']>;
        shouldLogin: boolean;
      }) => {
        let profile;

        if (body.shouldLogin) {
          // login user
          const loginRes = await supabase.auth.signIn({
            email: body.account.email,
            password: body.account.password,
          });
          if (loginRes.error) return { error: loginRes.error };
          const user = loginRes.user;
          if (!user) return { error: 'User not found' };
          // get user profile
          const { data: profileData, error: profileError } = await supabase
            .from<definitions['profiles']>('profiles')
            .select('*')
            .eq('id', user.id)
            .single();
          if (profileError) return { error: profileError };
          profile = camelize(profileData);
        } else {
          // signup new user
          const signupRes = await supabase.auth.signUp({
            email: body.account.email,
            password: body.account.password,
          });
          if (signupRes.error) return { error: signupRes.error };
          const user = signupRes.user;
          if (!user) return { error: 'User not found' };
          // generate url for user with first name and last name
          const url = await generateUniqueUrl(
            `${body.account.first_name}-${body.account.last_name}`.toLowerCase(),
            async (url: string) => {
              const { data, error } = await supabase
                .from('profiles')
                .select('question_url')
                .eq('question_url', url);
              if (error) throw error;
              return data && data.length > 0 ? false : true;
            },
          );
          // create new user's profile
          const { data: profileData, error: profileError } = await supabase
            .from<definitions['profiles']>('profiles')
            .insert({
              first_name: body.account.first_name,
              last_name: body.account.last_name,
              id: user.id,
              email: body.account.email,
              avatar_media_location: '',
              role: 'user',
              question_url: url,
            })
            .single();
          if (profileError) return { error: profileError };
          profile = camelize(profileData);
        }
        if (!profile) return { error: 'Profile not found' };
        // use profile when creating answer
        const { data: answer, error: answerError } = await supabase
          .from<definitions['answers']>('answers')
          .insert({
            ...body.answer,
            profile_id: profile.id,
          })
          .single();
        if (answerError) return { error: answerError };
        return { data: { profile, answer } };
      },
    }),
    getQuestionAnswers: builder.query({
      queryFn: async (questionId) => {
        const { data, error } = await supabase
          .from<definitions['answers']>('answers')
          .select('*, guests!id(*), profiles!id(*)')
          .eq('question_id', questionId);
        if (error) return { error };
        const formatted = data && (data.map((d) => camelize(d)) as Answer[]);
        return { data: formatted };
      },
    }),
  }),
});

export const {
  useCreateQuestionMutation,
  useGetMyQuestionsQuery,
  useGetQuestionToAnswerQuery,
  useAnswerQuestionAsGuestMutation,
  useAnswerQuestionWithAccountMutation,
  useGetQuestionAnswersQuery,
} = questionsApiSlice;
