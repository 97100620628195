import { useAppSelector } from '../store/store';

const useMyQuestionUrl = () => {
  const { profile } = useAppSelector((state) => state.auth);

  const myQuestionUrl = (url: string) => {
    if (!profile) return;
    return `${window.location.origin}/ask/${profile.questionUrl}/${url}`;
  };

  return {
    myQuestionUrl,
  };
};

export default useMyQuestionUrl;
