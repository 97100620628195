// templates/component/FullQuestionCard.tsx

import React, { useEffect, useRef } from 'react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { fadeIn } from '../../animation';

interface FullQuestionCardProps {
  subject: string;
  question: string;
  showExpand?: boolean;
}

const variants = {
  rotate: {
    rotate: 180,
  },
  stop: {
    rotate: 0,
  },
};

const FullQuestionCard: React.FC<FullQuestionCardProps> = ({
  subject,
  question,
  showExpand,
}) => {
  // hooks

  // state
  const [showMore, setShowMore] = React.useState(false);
  // refs

  // effects

  // methods
  return (
    <div className="w-full rounded-xl bg-white p-4">
      <div className="flex justify-between">
        <span className="text-xl font-semibold text-gray-600">{subject}</span>
        {showExpand && (
          <motion.div
            variants={variants}
            animate={showMore ? 'rotate' : 'stop'}
          >
            <ChevronUpIcon
              className="h-8 w-8 text-gray-600"
              onClick={() => setShowMore(!showMore)}
            />
          </motion.div>
        )}
      </div>
      <AnimatePresence initial={false}>
        {(showExpand && showMore) || !showExpand ? (
          <>
            <motion.hr variants={fadeIn} className="my-4 w-full bg-gray-200" />
            <motion.div
              className={`overflow-hidden ${showMore}`}
              variants={fadeIn}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <span className="whitespace-pre-line text-lg text-gray-700">
                {question}
              </span>
            </motion.div>
          </>
        ) : (
          <></>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FullQuestionCard;
