import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
} from '@ionic/react';
import { albums, person, paperPlane } from 'ionicons/icons';
import { Route, Redirect, Switch, useHistory } from 'react-router';
import Questions from './pages/Questions/Questions';
import Profile from './pages/Profile';
import AccountSettings from './pages/Settings';
import './index.css';
import Answers from './pages/answers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppSelector } from './store/store';
import { LoadingState } from './@types/app-types';
import { useExpertQuestionsListener } from './services/question.service';

/**
 * HOC that checks to see if a user has a profile.  if they don't,
 * then it redirects to onboarding.  If they do, show tabs/content
 */
const ProfileCheck: React.FC = ({ children }) => {
  const { profile, profileLoading } = useAppSelector((state) => state.auth);
  const history = useHistory();
  const [canRender, setCanRender] = useState<boolean>(false);
  useEffect(() => {
    if (profileLoading === LoadingState.LOADED) {
      if (profile) {
        setCanRender(true);
      } else {
        if (!history.location.pathname.includes('onboarding')) {
          history.push('/onboarding/name');
        }
      }
    }
  }, [profile, profileLoading]);

  return <>{canRender && children}</>;
};
/**
 * Handles all other routes after authentication
 */
const Tabs: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);
  useExpertQuestionsListener(user?.id);
  return (
    <ProfileCheck>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/questions">
            <Questions />
          </Route>
          <Route exact path="/answers">
            <Answers />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/profile/settings">
            <AccountSettings />
          </Route>
          <Redirect exact path="/" to="/questions" />
        </IonRouterOutlet>
        <IonTabBar slot="bottom" className=" bg-opacity-10 pt-2">
          <IonTabButton
            tab="questions"
            href="/questions"
            className=" bg-opacity-10"
          >
            <IonIcon icon={albums} />
            <IonLabel>Questions</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab="answers"
            href="/answers"
            className=" bg-opacity-10"
          >
            <IonIcon icon={paperPlane} />
            <IonLabel>Answers</IonLabel>
          </IonTabButton>

          <IonTabButton
            tab="profile"
            href="/profile"
            className=" bg-opacity-10"
          >
            <IonIcon icon={person} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </ProfileCheck>
  );
};

export default Tabs;
