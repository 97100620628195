import { Profile, Question } from '../@types/app-types';

export const sendQuestionAnswerEmail = async (questionId: number) => {
  try {
    const body = {
      questionId: questionId.toString(),
    };
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/sendgrid/new-question-answer`,
      {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const json = await data.json();
  } catch (error) {
    throw error;
  }
};

export const useSendgridService = () => {
  const sendAnswerEmail = async (question: Question) => {
    try {
      const body = {
        id: question.id,
      };
      const data = await fetch(
        `${process.env.REACT_APP_API_URL}/sendgrid/new-answer`,
        {
          body: JSON.stringify(body),
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const json = await data.json();
    } catch (error) {
      throw error;
    }
  };

  // leaving this here for now.  this should be handled in the node-api when the paid webhook is called
  const sendNewQuestionEmail = async (questionId: string) => {
    try {
      const body = {
        id: questionId,
      };
      const url = `${process.env.REACT_APP_API_URL}/sendgrid/new-question`;
      const data = await fetch(url, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const addExpertToSendgrid = async (profile: Profile) => {
    try {
      const body = {
        email: profile.email,
        firstName: profile.firstName,
        lastName: profile.lastName,
        questionUrl: profile.questionUrl,
      };
      const url = `${process.env.REACT_APP_API_URL}/sendgrid/add-to-marketing-list`;
      const data = await fetch(url, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const sendNewUserEmail = async (id: string) => {
    try {
      const body = {
        id,
      };
      const url = `${process.env.REACT_APP_API_URL}/sendgrid/new-user`;
      const data = await fetch(url, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const sendGenericEmail = async (subject: string, content: string) => {
    try {
      const body = {
        subject,
        content,
      };
      const url = `${process.env.REACT_APP_API_URL}/sendgrid/generic-email`;
      const data = await fetch(url, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      throw error;
    }
  };

  return {
    sendNewQuestionEmail,
    sendAnswerEmail,
    addExpertToSendgrid,
    sendNewUserEmail,
    sendGenericEmail,
  };
};
