// templates/component/MyQuestionUrl.tsx

import { IonIcon, useIonToast } from '@ionic/react';
import { motion } from 'framer-motion';
import React from 'react';
import useMyQuestionUrl from '../../hooks/useMyQuestionUrl';
import { linkOutline } from 'ionicons/icons';
import { copyToClipboard } from '../../helpers/misc';

interface MyQuestionUrlProps {
  questionUrl: string;
  showCopy?: boolean;
}

const MyQuestionUrl: React.FC<MyQuestionUrlProps> = ({
  questionUrl,
  showCopy,
}) => {
  // hooks
  const [present, dismiss] = useIonToast();
  const { myQuestionUrl } = useMyQuestionUrl();

  // state

  // refs

  // effects

  // methods
  const handleCopyLink = () => {
    if (questionUrl) {
      copyToClipboard(myQuestionUrl(questionUrl) as string);
      present({
        message: '✅  Link copied. Share it far and wide!',
        duration: 2500,
      });
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <span className="text-xl font-bold">Question URL:</span>
      <span className="select-all text-center text-lg text-gray-700">
        {myQuestionUrl(questionUrl)}
      </span>
      {showCopy && (
        <div className="mx-6 mt-2 flex justify-center">
          <motion.button
            className=" flex w-full items-center justify-center rounded-full border border-solid border-blue-600 bg-white px-4 py-2 shadow-md"
            onClick={handleCopyLink}
            whileTap={{ scale: 0.9 }}
          >
            <IonIcon icon={linkOutline} className="mr-2 text-blue-600" />
            <span className="font-semibold text-blue-600">Copy URL</span>
          </motion.button>
        </div>
      )}
    </div>
  );
};

export default MyQuestionUrl;
