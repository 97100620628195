import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/Button';
import PageTitle from '../../../components/PageTitle';
import { uploadAvatar } from '../../../helpers/supabase/utils';
import { useProfileService } from '../../../services/profile.service';
import { RootState, useAppSelector } from '../../../store/store';
import { resetOnboarding } from '../../../store/onboarding-state';
import OnboardingContentWrapper from '../components/OnboardingContentWrapper';
import Review from './Review';

const ReviewOnboarding = () => {
  const user = useAppSelector((state) => state.auth.user);
  const { photo, firstName, lastName, questionUrl } = useAppSelector(
    (state: RootState) => state.onboarding,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { upsertProfile } = useProfileService();

  const handleFinish = async () => {
    if (!user?.email) return;
    //Upload and push changes to the database here.
    try {
      const filePath = await uploadAvatar(photo || '/defaultAvatar.png');
      if (firstName && lastName && filePath) {
        await upsertProfile({
          firstName,
          lastName,
          filePath,
          questionUrl,
          email: user.email,
        });
        dispatch(resetOnboarding());
      } else {
        throw new Error('Unable to finish profile');
      }
      history.push('/questions');
    } catch (error) {
      throw error;
    }
  };
  return (
    <OnboardingContentWrapper title="Review" showBack={true} index={4}>
      <PageTitle
        title="Review Your Profile"
        className="text-center text-black"
      />
      <div className="flex flex-col items-center justify-center">
        <div>
          <Review
            photoUrl={photo || '/defaultAvatar.png'}
            firstName={firstName}
            lastName={lastName}
          />
        </div>
        <div className="w-4/5 pt-8">
          <Button text="Finish" onClick={() => handleFinish()} />
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default ReviewOnboarding;
