// templates/component/AddMyQuestionModal.tsx

import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { fadeIn } from '../../../../animation';
import Button from '../../../../components/Button';
import InputField from '../../../../components/InputField';
import ModalWrapper from '../../../../components/ModalWrapper';
import MultiComponentSlider from '../../../../components/MultiComponentSlider';
import QuestionDetailsStep from './QuestionDetailsStep';
import QuestionReviewStep from './QuestionReviewStep';
import QuestionUrlStep from './QuestionUrlStep';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useQuestionService } from '../../../../services/question.service';
import { useAppSelector } from '../../../../store/store';
import { useCreateQuestionMutation } from '../../../../store/questions-api-slice';
import { useIonToast } from '@ionic/react';

interface AddMyQuestionModalProps {
  onDismiss: () => void;
}

interface FormProps {
  subject: string;
  question: string;
  url: string;
  formattedUrl: string;
}

const AddMyQuestionModal: React.FC<AddMyQuestionModalProps> = ({
  onDismiss,
}) => {
  // hooks
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      subject: '',
      question: '',
      url: '',
      formattedUrl: '',
    },
  });
  const { checkIfQuestionUrlExists } = useQuestionService();
  const profile = useAppSelector((state) => state.auth.profile);
  const [createQuestion, { isLoading, isSuccess, isError }] =
    useCreateQuestionMutation();
  const [present] = useIonToast();

  // state
  const [[page, direction], setPage] = useState([0, 0]);
  const [progress, setProgress] = useState(0);
  const [stepsValid, setStepsValid] = useState<number[]>([2]);
  const pages = [
    <QuestionDetailsStep setValid={(valid) => handleStepValid(0, valid)} />,
    <QuestionUrlStep setValid={(valid) => handleStepValid(1, valid)} />,
    <QuestionReviewStep />,
  ];
  // refs

  // effects
  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        onDismiss();
        present({
          message: 'Your question has been posted!',
          duration: 2500,
        });
        return;
      }
      if (isError) {
        present({
          message: 'Error posting question. Please try again.',
          duration: 2500,
        });
      }
    }
  }, [isLoading, isSuccess, isError]);

  // methods
  const paginate = (newDirection: number) => {
    const newPage = page + newDirection;
    setPage([newPage, newDirection]);
    // set progress based on the page
    const pageProgress = Math.floor((newPage / pages.length) * 100);
    setProgress(pageProgress);
  };

  const handleNext = async () => {
    if (!profile) return;
    if (page < pages.length - 1) {
      if (page === 1) {
        // check if url exists on submit
        const exists = await checkIfQuestionUrlExists(
          methods.getValues('formattedUrl'),
          profile.id,
        );
        if (exists) {
          methods.setError('url', {
            type: 'manual',
            message: 'This url is already in use',
          });
          return;
        }
      }
      paginate(1);
    } else {
      handleContinue(methods.getValues());
    }
  };

  const handleBack = () => {
    if (page > 0) {
      paginate(-1);
    } else {
      onDismiss();
    }
  };

  const stepValid = () => {
    if (stepsValid.includes(page)) {
      return true;
    } else {
      return false;
    }
  };

  const handleStepValid = (step: number, isValid: boolean) => {
    if (isValid) {
      const set = new Set(stepsValid);
      set.add(page);
      setStepsValid(Array.from(set));
    } else {
      setStepsValid(stepsValid.filter((s) => s !== step));
    }
  };

  const handleContinue = (data: FormProps) => {
    if (!profile) return;
    const { subject, question, url, formattedUrl } = data;
    if (!formattedUrl || formattedUrl.length === 0) return;
    // side effects occur in useEffect.
    createQuestion({
      subject,
      question,
      url: formattedUrl,
      profile_id: profile.id,
    });
  };

  return (
    <ModalWrapper
      title="Add Question"
      onDismiss={onDismiss}
      gridClass="min-h-[90%] h-[90%]"
    >
      <div className="h-full px-4">
        <div className="flex h-full flex-col justify-between">
          <FormProvider {...methods}>
            <MultiComponentSlider
              pages={pages}
              page={page}
              direction={direction}
            />
          </FormProvider>
        </div>
        <div className="flex gap-2">
          {page > 0 && (
            <motion.button
              onClick={handleBack}
              type="button"
              className="rounded-md border border-solid border-blue-700 text-base text-blue-700 shadow-sm"
              whileTap={{ scale: 0.95 }}
            >
              <ChevronLeftIcon className="h-10 w-10" />
            </motion.button>
          )}
          <Button
            className="w-full"
            onClick={handleNext}
            disabled={!stepValid() || isLoading}
            text={page === pages.length - 1 ? 'Post Question!' : 'Continue'}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default AddMyQuestionModal;
