import { motion } from 'framer-motion';

export const wrapperVariants = {
  hidden: {
    opacity: 0,
    y: 25,
    transition: {
      staggerChildren: 0.2,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.3,
      when: 'beforeChildren',
    },
  },
  exit: {
    opacity: 0,
    y: -25,
  },
};

const QuestionLoading = () => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={wrapperVariants}
      className="px-4"
    >
      <motion.div className="loading-card-height mt-12 w-full animate-pulse rounded-2xl bg-gray-300 opacity-25"></motion.div>
      <motion.div className="loading-card-height mt-12 w-full animate-pulse rounded-2xl bg-gray-300 opacity-25"></motion.div>
      <motion.div className="loading-card-height mt-12 w-full animate-pulse rounded-2xl bg-gray-300 opacity-25"></motion.div>
      <motion.div className="loading-card-height mt-12 w-full animate-pulse rounded-2xl bg-gray-300 opacity-25"></motion.div>
    </motion.div>
  );
};

export default QuestionLoading;
