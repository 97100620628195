import { useDispatch } from 'react-redux';
import { definitions } from '../@types/api-types';
import { Profile } from '../@types/app-types';
import { camelize, snakeize } from '../helpers/api-transformations';
import { supabase } from '../helpers/supabase/supabaseClient';
import {
  getProfile,
  getProfileSuccess,
  getProfileFailure,
  upsertProfile as upserProfileAction,
  upsertProfileFailure,
  upsertProfileSuccess,
} from '../auth/store/auth-state';
import { useHistory } from 'react-router-dom';
import { useSendgridService } from './sendgrid.service';

export const useProfileService = () => {
  const dispatch = useDispatch();
  const { addExpertToSendgrid, sendNewUserEmail } = useSendgridService();

  const getUserProfile = async (id: string) => {
    dispatch(getProfile());
    try {
      const { data, error } = await supabase
        .from<definitions['profiles']>('profiles')
        .select('*')
        .eq('id', id);
      if (error) throw error;
      const profile = data
        ? (data.map((user) => camelize(user))[0] as Profile)
        : null;
      dispatch(getProfileSuccess(profile));
    } catch (error) {
      console.error(error);
      dispatch(getProfileFailure(error));
      throw error;
    }
  };

  const getProfileByUrl = async (questionUrl: string) => {
    try {
      const { data, error } = await supabase
        .from<definitions['profiles']>('profiles')
        .select('*')
        .eq('question_url', questionUrl);
      if (error) throw error;
      return data ? (data.map((user) => camelize(user))[0] as Profile) : null;
    } catch (error) {
      throw error;
    }
  };

  const updateProfile = async (
    id: number | string,
    profile: Partial<Profile>,
  ) => {
    try {
      const snake = snakeize(profile);
      const { data, error } = await supabase
        .from<definitions['profiles']>('profiles')
        .update(snake)
        .match({ id })
        .single();
      if (error) throw error;
      return data ? camelize(data) : null;
    } catch (error) {
      throw error;
    }
  };

  const checkQuestionUrl = async (url: string) => {
    try {
      const { data, error } = await supabase
        .from('profiles')
        .select('question_url')
        .eq('question_url', url);
      if (error) throw error;
      return data && data.length > 0 ? false : true;
    } catch (error) {
      throw error;
    }
  };

  const upsertProfile = async ({
    firstName,
    lastName,
    filePath,
    questionUrl,
    email,
  }: {
    firstName: string;
    lastName: string;
    filePath: string;
    questionUrl: string;
    email: string;
  }) => {
    try {
      dispatch(upserProfileAction());
      const user = supabase.auth.user();
      const updates = {
        id: user?.id,
        first_name: firstName,
        last_name: lastName,
        avatar_media_location: filePath,
        question_url: questionUrl,
        updated_at: new Date(),
        email,
      };
      const { data, error } = await supabase
        .from('profiles')
        .upsert(updates)
        .single();
      dispatch(upsertProfileSuccess(data));
      // need to populate full profile in redux
      if (data && user) getUserProfile(user.id);
      if (error) {
        throw error;
      }
      const profile = data ? camelize(data) : null;
      if (profile) {
        addExpertToSendgrid(profile);
        sendNewUserEmail(profile.id);
      }
    } catch (error) {
      dispatch(upsertProfileFailure(error));
      throw error;
    }
  };

  return {
    getUserProfile,
    getProfileByUrl,
    updateProfile,
    checkQuestionUrl,
    upsertProfile,
  };
};
