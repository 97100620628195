import {
  IonButton,
  IonButtons,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import './index.css';
import Button from '../../components/Button';
import { useAuth } from '../../auth/services/auth.services';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { cog, linkOutline } from 'ionicons/icons';
import ViewProfile from './components/view-profile';
import { copyToClipboard } from '../../helpers/misc';
import { motion } from 'framer-motion';
import { useAppSelector } from '../../store/store';

const Profile: React.FC = () => {
  const { signOut } = useAuth();
  const { user, profile } = useAppSelector((state) => state.auth);
  const history = useHistory();
  const [questionUrl, setQuestionUrl] = useState<string>('');
  const [present, dismiss] = useIonToast();

  const handleLogOut = () => {
    if (user) {
      signOut();
    }
  };

  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (profile) {
      const questionUrl = `${window.location.origin}/ask/${profile.questionUrl}`;
      setQuestionUrl(questionUrl);
    }
  }, [JSON.stringify(profile)]);

  const handleRedirect = (path: string) => {
    history.push(`${path}`);
  };

  const handleCopyLink = () => {
    if (profile) {
      copyToClipboard(questionUrl);
      present({
        message: '✅  Link copied. Share it far and wide!',
        duration: 3000,
      });
    }
  };

  return (
    <IonPage>
      <IonHeader className="bg-white">
        <IonToolbar>
          <IonTitle className="text-black">Profile</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => handleRedirect('/profile/settings')}>
              <IonIcon icon={cog}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Profile</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid fixed>
          {user && profile && (
            <div className="flex flex-col pb-4">
              <ViewProfile
                profile={profile}
                itemClick={() => setEditing(true)}
              />
            </div>
          )}
          <p className="mt-8 text-center font-semibold">
            Share the link below so people can ask you questions!
          </p>

          <p className="mt-4 select-all text-center font-semibold text-gray-800 underline">
            {questionUrl}
          </p>
          <div className="mt-4 px-12">
            <motion.div
              className=" space-between flex items-center justify-center rounded-full border border-blue-600 px-4 py-2 shadow-xl"
              onClick={() => handleCopyLink()}
              whileTap={{ scale: 0.9 }}
            >
              <IonIcon
                icon={linkOutline}
                className="mr-2 text-blue-600"
              ></IonIcon>
              <span className="font-semibold text-blue-600">Copy my link</span>
            </motion.div>
          </div>
          <div className="mt-12 px-12">
            <Button onClick={() => handleLogOut()} text="Log out" />
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
