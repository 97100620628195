// templates/component/AnswerQuestionAccountStep.tsx

import { motion } from 'framer-motion';
import { mail, lockClosed } from 'ionicons/icons';
import React, { useState } from 'react';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import { fadeIn } from '../../../../animation';
import Button from '../../../../components/Button';
import InputField from '../../../../components/InputField';

interface AnswerQuestionAccountStepProps {
  handleSubmit: (shouldLogin: boolean) => void;
}

const AnswerQuestionAccountStep: React.FC<AnswerQuestionAccountStepProps> = ({
  handleSubmit,
}) => {
  // hooks
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();
  const accountType = useWatch({ control, name: 'accountType' });
  // state
  const [showLogin, setShowLogin] = useState(false);
  // refs

  // effects

  // methods
  const handleAccountChange = () => {
    if (accountType === 'guest') {
      setValue('accountType', 'create');
    } else {
      setValue('accountType', 'guest');
    }
  };
  const login = () => {};
  return (
    <motion.div variants={fadeIn} className="flex flex-col gap-10 p-4">
      <form>
        {(!showLogin || accountType === 'guest') && (
          <motion.div variants={fadeIn} className="flex flex-col gap-4">
            <InputField
              label="First Name"
              type="text"
              name="firstName"
              register={register}
              error={errors.firstName as FieldError}
            />
            <InputField
              label="Last Name"
              type="text"
              name="lastName"
              register={register}
              error={errors.lastName as FieldError}
            />
          </motion.div>
        )}
        {accountType === 'create' && (
          <>
            <motion.div variants={fadeIn}>
              <InputField
                label="Email"
                name="email"
                type="email"
                icon={mail}
                placeholder="youremail@gmail.com"
                autoFocus={true}
                register={register}
                hasPattern={{
                  value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                  message: 'Please use a valid email address',
                }}
                error={errors.email as FieldError}
              />
            </motion.div>
            <motion.div variants={fadeIn}>
              <InputField
                label="Password"
                name="password"
                type="password"
                icon={lockClosed}
                placeholder="password"
                register={register}
                isRequired="You must have a password"
              />
            </motion.div>
          </>
        )}
      </form>
      <Button
        text={
          accountType === 'guest'
            ? 'Submit Answer'
            : showLogin
            ? 'Log in'
            : 'Sign up'
        }
        onClick={() => handleSubmit(showLogin)}
        className="w-full"
      />
      {accountType === 'create' && (
        <motion.div variants={fadeIn} className="flex justify-center">
          <p className="mt-4">
            {!showLogin
              ? 'Already have an account?'
              : "I don't have an account"}{' '}
            <span
              onClick={() => setShowLogin(!showLogin)}
              className="cursor-pointer font-semibold text-gray-900 underline"
            >
              {showLogin ? 'Sign Up' : 'Log in'}
            </span>
          </p>
        </motion.div>
      )}
      <div className="flex flex-col items-center justify-center gap-8">
        <span className="text-xl font-semibold">Changed your mind?</span>
        <Button
          onClick={handleAccountChange}
          text={
            accountType === 'guest'
              ? 'Sign Up or Log in'
              : 'Continue as a guest'
          }
        />
      </div>
      {}
    </motion.div>
  );
};

export default AnswerQuestionAccountStep;
