import { motion } from 'framer-motion';

interface GoogleSignInProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string;
}
const GoogleSignInButton: React.FC<GoogleSignInProps> = ({
  text,
  ...props
}) => (
  <motion.div
    whileTap={{ scale: 0.95 }}
    className="relative flex items-center justify-center rounded-md border border-gray-300 bg-white py-2 focus:border-blue-500"
    onClick={props.onClick}
  >
    <img src={'./GoogleIcon.png'} className=" absolute left-2 h-8 w-8" />
    <p className="font-semibold">{text} with Google</p>
  </motion.div>
);

export default GoogleSignInButton;
