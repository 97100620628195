import { useEffect, useRef, useState } from 'react';
import { ReactComponent as Play } from './assets/play.svg';
import { ReactComponent as Pause } from './assets/pause.svg';
import { useAudio } from '../../../../hooks/use-audio';
import unmuteAudio from 'unmute-ios-audio';
import './IOSAudioPlayer.css';
import { motion } from 'framer-motion';

const IOSAudioPlayer: React.FC<{ url: string; singleLine?: boolean }> = ({
  url,
  singleLine,
}) => {
  // this is needed to allow audio to play when IOS is muted.
  unmuteAudio();

  const {
    duration,
    playedDuration,
    setCurrentTime,
    playedTime,
    totalTime,
    play,
    pause,
    playing,
    setAudioUrl,
  } = useAudio();

  const seekSlider = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (url) {
      setAudioUrl(url);
    } else {
      setAudioUrl(undefined);
    }
  }, [url]);

  useEffect(() => {
    if (seekSlider.current) {
      seekSlider.current.value = playedDuration.toString();
    }
  }, [playedDuration]);

  return (
    <div className="w-full">
      {singleLine ? (
        <div className="flex items-center gap-4 rounded-full bg-gray-200 py-2 px-4">
          {playing ? (
            <motion.button
              type="button"
              className="flex items-center justify-center"
              onClick={() => pause()}
              aria-label="Pause"
              whileTap={{ scale: 0.9 }}
            >
              <Pause className="h-4 w-4" />
            </motion.button>
          ) : (
            <motion.button
              type="button"
              className="flex items-start justify-center"
              onClick={() => {
                play();
              }}
              aria-label="Play"
              whileTap={{ scale: 0.9 }}
            >
              <Play className="h-4 w-4" />
            </motion.button>
          )}
          <div className="flex w-full grow justify-center">
            <span>{playedTime}</span>/<span>{totalTime}</span>
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full py-4">
            <input
              ref={seekSlider}
              type="range"
              min={0}
              max={duration}
              className="progress"
              onInput={(e) =>
                seekSlider.current &&
                setCurrentTime(parseInt(seekSlider.current.value))
              }
              onChange={(e) => {
                if (seekSlider.current) {
                  setCurrentTime(parseInt(seekSlider.current.value));
                }
              }}
            />
          </div>
          <div className="flex w-full justify-between">
            <span>{playedTime}</span>
            <span>{totalTime}</span>
          </div>
          <div className="flex justify-center">
            {playing ? (
              <motion.button
                type="button"
                className="pause flex items-center justify-center rounded-full p-6"
                onClick={() => pause()}
                aria-label="Pause"
                whileTap={{ scale: 0.9 }}
              >
                <Pause className="h-6 w-6" />
              </motion.button>
            ) : (
              <motion.button
                type="button"
                className="play flex items-center justify-center rounded-full p-6"
                onClick={() => {
                  play();
                }}
                aria-label="Play"
                whileTap={{ scale: 0.9 }}
              >
                <Play className="h-6 w-6" />
              </motion.button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default IOSAudioPlayer;
