import { IonIcon } from '@ionic/react';
import { trash, send } from 'ionicons/icons';

export const RecordPlayback: React.FC<{
  handleCancel: () => void;
  handleSend: () => void;
}> = ({ handleCancel, handleSend }) => {
  return (
    // TODO: Make the play button line up with the send and delete buttons
    <div className="audio-player flex w-full flex-col items-center gap-y-2">
      <div className="flex w-full items-center justify-around">
        <button
          className="h-12 w-12 rounded-full bg-[#EF7263] p-1 text-white shadow-lg"
          onClick={handleCancel}
        >
          <IonIcon icon={trash} className="h-6 w-6" />
        </button>
        <button
          className="h-12 w-12 rounded-full bg-blue-600 p-1 text-white"
          onClick={handleSend}
        >
          <IonIcon icon={send} className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
};

export default RecordPlayback;
