import { createSlice } from '@reduxjs/toolkit';

export interface OnboardingState {
  firstName: string;
  lastName: string;
  photo: string | null;
  questionUrl: string;
}

const initialState = {
  firstName: '',
  lastName: '',
  photo: null,
  questionUrl: '',
} as OnboardingState;

const storage = window.sessionStorage;

export const onboardingState = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboarding: (
      state,
      { payload }: { payload: { key: string; value: any } },
    ) => {
      (state as any)[payload.key] = payload.value;
      storage.setItem(payload.key, JSON.stringify(payload.value));
    },
    resetOnboarding: () => {
      storage.clear();
      return initialState;
    },
  },
});

export const { setOnboarding, resetOnboarding } = onboardingState.actions;

export default onboardingState.reducer;
