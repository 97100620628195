import { useEffect, useRef, useState } from 'react';
import {
  AudioBufferSourceNode,
  AudioContext,
  IAudioBufferSourceNode,
  IAudioContext,
} from 'standardized-audio-context';

export const useAudio = () => {
  const [audioUrl, setAudioUrl] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [playedDuration, setPlayedDuration] = useState<number>(0);
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const [totalTime, setTotalTime] = useState<string>('0:00');
  const [playedTime, setPlayedTime] = useState<string>('0:00');
  const [timer, setTimer] = useState<NodeJS.Timer>();

  const sourceRef = useRef<IAudioBufferSourceNode<IAudioContext> | undefined>(
    undefined,
  );

  const timerRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (audioUrl) {
      decodeAudio(audioUrl);
    } else {
      sourceRef.current = undefined;
    }
  }, [audioUrl]);

  useEffect(() => {
    if (timer) {
      return clearInterval(timer);
    }
  }, []);

  const decodeAudio = async (url: string) => {
    setLoading(true);
    const context = new AudioContext();
    const audioSource = context.createBufferSource();
    const res = await fetch(url);
    const buffer = await res.arrayBuffer();
    const audioBuffer = await context.decodeAudioData(buffer);
    audioSource.buffer = audioBuffer;
    audioSource.connect(context.destination);
    sourceRef.current = audioSource;
    sourceRef.current.onended = (e: any) => {
      const current = e.target?.context.currentTime;
      stopTimer();
      setPlaying(false);
      // if played === duration, then it hit the end.
      if (current >= duration - start) {
        setCurrentTime(0);
      } else {
        if (sourceRef.current) {
          // setCurrentTime(sourceRef.current.context.currentTime);
          setCurrentTime(current + start);
        }
      }
    };
    setLoading(false);
    setDuration(Math.floor(audioBuffer.duration));
    setTotalTime(calculateTime(audioBuffer.duration));
    setCurrentTime(playedDuration);
  };

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${minutes}:${returnedSeconds}`;
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      if (sourceRef.current) {
        const currentTime = sourceRef.current.context.currentTime + start;
        const rounded = Math.floor(currentTime);
        setPlayedDuration(rounded);
        setPlayedTime(calculateTime(rounded));
      }
    }, 250);
    // setTimer(newTimer);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const play = async () => {
    if (audioUrl) {
      setPlaying(true);
      if (!playing) {
        await decodeAudio(audioUrl);
      }
      // source?.start(0);
      sourceRef.current?.start(0, start);
      startTimer();
    }
  };

  const pause = () => {
    if (sourceRef.current) {
      // source?.stop();
      sourceRef.current?.stop();

      setPlaying(false);
      stopTimer();
    }
  };

  const setCurrentTime = (time: number) => {
    if (sourceRef.current) {
      if (playing) {
        pause();
      }
      setStart(time);
      setPlayedTime(calculateTime(time));
      setPlayedDuration(time);
    }
  };

  return {
    calculateTime,
    setAudioUrl,
    loading,
    duration,
    start,
    end,
    playing,
    play,
    pause,
    totalTime,
    setCurrentTime,
    playedTime,
    playedDuration,
    sourceRef,
  };
};
