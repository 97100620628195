export const copyCurrentLink = () => {
  const el = document.createElement('input');
  el.value = window.location.href;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const copyToClipboard = (text: string) => {
  // const el = document.createElement('input');
  // el.value = text;
  // document.body.appendChild(el);
  // el.select();
  // document.execCommand('copy');
  // document.body.removeChild(el);
  navigator.clipboard.writeText(text);
};

export const formatUrl = (url: string) => {
  // make all characters lowercase for url
  const lowercase = url.toLocaleLowerCase();
  // replace all characters that we might have to "encode".  Replace space with dashes
  const replaced = lowercase
    .split(' ')
    .join('-')
    .replace(/[^a-z0-9_.\-~]/g, '');
  return replaced;
};

export const generateUniqueUrl = async (
  urlToCheck: string,
  uniqueCheckFn: (url: string) => Promise<boolean>,
  duplicateCheck: number = 0,
): Promise<string> => {
  if (duplicateCheck && duplicateCheck > 0) {
    urlToCheck = `${urlToCheck}-${duplicateCheck}`;
  }
  const isUnique = await uniqueCheckFn(urlToCheck);
  if (isUnique) {
    return urlToCheck;
  } else {
    const suffix = duplicateCheck + 1;
    return generateUniqueUrl(urlToCheck, uniqueCheckFn, suffix);
  }
};
