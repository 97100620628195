import { IonButton } from '@ionic/react';
import React, { useRef, useState } from 'react';
import ReactAvatarEditor from 'react-avatar-editor';
import Button from '../../../../components/Button';

const AvatarCropper: React.FC<{
  initialImage: string;
  handleNewImage: (image: string) => void;
}> = ({ initialImage = '', handleNewImage }) => {
  //Controls for the ReactAvatarEditor
  const [width, setWidth] = useState<number>(200);
  const [height, setHeight] = useState<number>(200);
  const [scale, setScale] = useState<number>(1.0);
  const [allowZoomOut, setAllowZoomOut] = useState<boolean>(false);
  const [rotate, setRotate] = useState<number>(0.0);
  const [borderRadius, setBoarderRadius] = useState<number>(50);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0.5,
    y: 0.5,
  });
  const [image, setImage] = useState<string>(initialImage);
  const [imageURL, setImageURL] = useState<string>('');
  const [tempImage, setTempImage] = useState<string>('');
  const [showPreview, setShowPreview] = useState(false);

  //Set the ref to get the resulting image
  const editorRef: React.MutableRefObject<any> = useRef(null);

  //Methods for the ReactAvatarEditor
  const handlePositionChange = (position: any) => {
    setPosition(position);
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setImage(file);
    if (showPreview) setShowPreview(false);
  };

  const handleScale = (e: any) => {
    setScale(parseFloat(e.target.value));
  };

  const onCrop = () => {
    if (editorRef) {
      const url = editorRef.current.getImageScaledToCanvas().toDataURL();
      setImage(url);
      handleNewImage(url);
    }
  };

  return (
    <div className="p-8">
      {!showPreview ? (
        <div>
          <ReactAvatarEditor
            ref={editorRef}
            scale={scale}
            width={width}
            height={height}
            position={position}
            onPositionChange={handlePositionChange}
            rotate={rotate}
            borderRadius={width / (100 / borderRadius)}
            image={image}
            className="editor-canvas mx-auto touch-none rounded-md"
          />
        </div>
      ) : (
        <div>
          <img
            src={tempImage}
            width="200"
            height="200"
            className="mx-auto rounded-full motion-safe:animate-spin"
            alt="temporary"
          />
        </div>
      )}
      <div className="mx-auto mt-4 flex w-4/5 flex-row justify-center">
        <input
          name="scale"
          type="range"
          onChange={handleScale}
          min={allowZoomOut ? '0.1' : '1'}
          max="2"
          step="0.01"
          defaultValue="1"
          className="w-4/5"
        />
      </div>
      <div className="m-4 mt-6">
        <Button
          onClick={() => document.getElementById('newImage')?.click()}
          text="Select new photo"
          bgColor="bg-gray-200"
          hoverColor="hover:bg-gray-300"
          textColor="text base"
        />
        <input
          id="newImage"
          name="newImage"
          type="file"
          onChange={handleImageChange}
          className="hidden"
        />
      </div>

      <div className="m-4 mt-6">
        <Button onClick={onCrop} text="Crop & Save" />
      </div>
    </div>
  );
};

export default AvatarCropper;
