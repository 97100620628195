import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { AudioContext } from 'standardized-audio-context';
import { fadeIn } from '../../animation';
import Recording from '../../animation/Recording';
import { useAudio } from '../../hooks/use-audio';
import AudioPlayer from '../../pages/Response/components/Player';
import IOSAudioPlayer from '../../pages/Response/components/Player/IOSAudioPlayer';
import RecordPlayback from '../../pages/Response/components/RecordPlayback';

const AudioRecorder: React.FC<{ handleSend: (url: string) => void }> = ({
  handleSend,
}) => {
  const { calculateTime } = useAudio();
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const timerRef = useRef<NodeJS.Timer>();
  const recordingSecondsRef = useRef<number>(0);
  const [displayedTime, setDisplayedTime] = useState('0:00');
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    previewAudioStream,
    clearBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    blobPropertyBag: {
      type: 'audio/mpeg',
    },
  });

  useEffect(() => {
    audioRef.current.srcObject = previewAudioStream;
  }, [previewAudioStream]);

  useEffect(() => {
    if (status === 'recording') {
      startTimer();
    } else {
      stopTimer();
    }
  }, [status]);

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      const newTime = recordingSecondsRef.current + 1;
      setDisplayedTime(calculateTime(newTime));
      recordingSecondsRef.current = newTime;
    }, 1000);
  };

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const clearRecording = () => {
    clearBlobUrl();
    recordingSecondsRef.current = 0;
    setDisplayedTime('0:00');
  };

  return (
    <div>
      <AnimatePresence exitBeforeEnter>
        {mediaBlobUrl ? (
          <motion.div
            className="flex w-full flex-col items-center justify-center p-8"
            key="after-recording"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={fadeIn}
          >
            <p className="pb-4 text-lg font-semibold text-gray-400">
              Review your response
            </p>
            <IOSAudioPlayer url={mediaBlobUrl} />
            <div className="relative z-10 w-full">
              <RecordPlayback
                handleCancel={() => clearRecording()}
                handleSend={() => handleSend(mediaBlobUrl)}
              />
            </div>
          </motion.div>
        ) : status === 'recording' ? (
          <motion.div
            className="flex w-full flex-col items-center justify-center py-8"
            key="recording"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={fadeIn}
          >
            <div>
              <p className="font-semibold text-black">{displayedTime}</p>
            </div>
            <motion.div
              className="mt-4 w-20"
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={fadeIn}
            >
              <Recording />
            </motion.div>
            <div className="w-[175px] px-12">
              <motion.div
                className="card-shadow mt-8 flex justify-center rounded-full p-5 text-amber-800"
                whileTap={{ scale: 0.9 }}
                onClick={() => {
                  stopRecording();
                }}
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeIn}
              >
                <div className="h-8 w-8 rounded-md bg-[#EF7263]" />
              </motion.div>
            </div>
          </motion.div>
        ) : (
          <div
            className="flex w-full flex-col items-center justify-center py-8"
            key="before-recording"
          >
            <div>
              <p className="text-lg font-semibold text-gray-400">
                Tap to record your answer
              </p>
            </div>
            <motion.div
              className="mic-shadow mt-8 flex justify-center rounded-full p-6 text-amber-800"
              whileTap={{ scale: 0.9 }}
              onClick={() => startRecording()}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={fadeIn}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 20 20"
                fill="#EF7263"
              >
                <path
                  fillRule="evenodd"
                  d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                  clipRule="evenodd"
                />
              </svg>
            </motion.div>
          </div>
        )}
        <div className="flex w-full justify-center py-4 px-10">
          <p className="text-center text-gray-400">
            Make sure to stay on this page. Thanks!
          </p>
        </div>
      </AnimatePresence>
    </div>
  );
};

export default AudioRecorder;
