import { motion } from 'framer-motion';
import React from 'react';

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  text: any;
  onClick?: any;
  bgColor?: string;
  textColor?: string;
  hoverColor?: string;
  disabled?: boolean;
  icon?: any;
}

/**
 * Make sure to pass full tailwind classes to the following props. Example below:
 *
 * @param {string} textColor='text-blue-600'
 * @param {string} bgColor="bg-blue-100
 * @param {string} hoverColor="hover:bg-blue-900"
 */

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ text, bgColor, textColor, hoverColor, disabled, icon, ...props }, ref) => {
    return (
      <motion.button
        type="button"
        className={`relative w-full rounded-md border-2 px-4 py-2 text-base font-medium shadow-sm
      ${textColor ? textColor : 'text-white'}
      ${bgColor ? bgColor : 'bg-blue-700'}
      ${hoverColor ? hoverColor : 'hover:bg-blue-800 '}
      ${props.className ? props.className : ''}
    `}
        onClick={props.onClick}
        whileTap={{ scale: 0.95 }}
        ref={ref}
        disabled={disabled}
      >
        {text}
        {icon && (
          <div className="absolute top-0 right-0 bottom-0 mr-4 flex h-full items-center">
            {icon}
          </div>
        )}
      </motion.button>
    );
  },
);

export default Button;
