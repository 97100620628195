import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LoadingState } from '../../../@types/app-types';
import { RootState } from '../../../store/store';
import useAnalyticsService from '../../../services/analytics.service';

const GoogleRedirect: React.FC = () => {
  const { trackRecommendedEvent } = useAnalyticsService();
  const { profile, profileLoading } = useSelector(
    (state: RootState) => state.auth,
  );
  const history = useHistory();
  useEffect(() => {
    if (profileLoading === LoadingState.LOADED) {
      handleProfile();
    }
  }, [profileLoading]);

  const handleProfile = () => {
    if (profile) {
      history.push('/');
    } else {
      trackRecommendedEvent('sign_up', { method: 'Google' });
      history.push('/onboarding');
    }
  };

  return <div></div>;
};

export default GoogleRedirect;
