const PageTitle: React.FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => (
  <div className="w-full p-8 text-left">
    <h1 className={`text-3xl font-bold ${className}`}>{title}</h1>
  </div>
);

export default PageTitle;
