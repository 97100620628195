import { createSlice } from '@reduxjs/toolkit';
import { CallState, LoadingState, Profile } from '../../@types/app-types';
import { User } from '@supabase/supabase-js';

export const authState = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    profile: null,
    authEvent: null,
    authLoading: LoadingState.INIT,
    loginLoading: LoadingState.INIT,
    logoutLoading: LoadingState.INIT,
    profileLoading: LoadingState.INIT,
    profileUpsert: LoadingState.INIT,
    signupLoading: LoadingState.INIT,
  } as {
    user: User | null;
    profile: Profile | null;
    authLoading: CallState;
    loginLoading: CallState;
    logoutLoading: CallState;
    authEvent: string | null;
    profileLoading: CallState;
    profileUpsert: CallState;
    signupLoading: CallState;
  },
  reducers: {
    initUser: (state) => {
      state.authLoading = LoadingState.LOADING;
    },
    initUserSuccess: (state, { payload }) => {
      state.authLoading = LoadingState.LOADED;
      state.user = payload;
    },
    initUserFailure: (state, { payload }) => {
      state.authLoading = { error: payload };
    },
    signup: (state) => {
      state.signupLoading = LoadingState.LOADING;
    },
    signupSuccess: (state, { payload }) => {
      state.signupLoading = LoadingState.LOADED;
      state.user = payload;
    },
    signupFailure: (state, { payload }) => {
      state.signupLoading = { error: payload };
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setAuthEvent: (state, { payload }) => {
      state.authEvent = payload;
    },
    setAuthLoading: (state, { payload }) => {
      state.authLoading = payload;
    },
    // logout is set to reset app state in rootReducer
    logout: (state, { payload }) => {
      state.logoutLoading = LoadingState.LOADING;
    },
    logoutSuccess: (state, { payload }) => {
      state.logoutLoading = LoadingState.LOADED;
      state.authLoading = LoadingState.LOADED;
      state.user = null;
    },
    login: (state) => {
      state.loginLoading = LoadingState.LOADING;
    },
    loginSuccess: (state, { payload }) => {
      state.loginLoading = LoadingState.LOADED;
      state.user = payload;
    },
    loginError: (state, { payload }) => {
      state.loginLoading = LoadingState.LOADED;
    },
    getProfile: (state) => {
      state.profileLoading = LoadingState.LOADING;
    },
    getProfileSuccess: (state, { payload }) => {
      state.profileLoading = LoadingState.LOADED;
      state.profile = payload;
    },
    getProfileFailure: (state, { payload }) => {
      state.profileLoading = { error: payload };
    },
    upsertProfile: (state) => {
      state.profileUpsert = LoadingState.LOADING;
    },
    upsertProfileSuccess: (state, { payload }) => {
      state.profileUpsert = LoadingState.LOADED;
      state.profile = payload;
    },
    upsertProfileFailure: (state, { payload }) => {
      state.profileUpsert = { error: payload };
    },
  },
});

export const {
  setUser,
  setAuthLoading,
  initUser,
  initUserSuccess,
  initUserFailure,
  logout,
  logoutSuccess,
  login,
  loginSuccess,
  loginError,
  setAuthEvent,
  getProfile,
  getProfileSuccess,
  getProfileFailure,
  signup,
  signupSuccess,
  signupFailure,
  upsertProfile,
  upsertProfileFailure,
  upsertProfileSuccess,
} = authState.actions;

export default authState.reducer;
