import { motion } from 'framer-motion';

const WaveFormOne: React.FC = () => {
  const pathVariants = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 3,
      },
    },
  };
  return (
    <>
      <div className="mb-8 flex w-full justify-end">
        <div className="relative">
          <svg
            width="331"
            height="142"
            viewBox="0 0 331 142"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <motion.path
                d="M7 69.7437L15.3519 84.8132C20.287 93.647 25.6018 93.1274 27.8796 83.6007L32.8148 59.6974C37.3704 37.6994 43.0648 37.8727 45.7222 60.0438L49.8981 98.3238C54.4537 139.202 60.1481 139.029 62.8055 98.1506L66.9815 31.8102C69.6389 -9.24109 75.3333 -9.24109 79.8889 31.637L84.8241 77.5383C86.7222 95.5524 92.4167 95.8988 97.7315 78.2311L100.389 69.3973C103.426 59.1778 109.12 59.0046 112.917 69.0509L117.093 80.1365C120.13 87.7578 125.444 87.931 129.241 80.4829L134.935 69.0509C137.213 64.2009 142.528 63.6813 146.324 68.0116L151.259 73.5544C154.296 76.8454 158.852 76.8454 161.889 73.5544L164.167 71.1294C169.481 65.2402 174.796 66.2795 175.935 73.3812L180.491 99.5363C184.287 122.574 189.981 122.574 193.398 99.3631L199.852 53.8082C202.509 34.9281 208.204 34.7548 212.759 53.4618L213.519 56.926C218.074 75.8062 223.768 75.633 226.426 56.5796L230.602 25.7478C235.157 -6.6429 240.852 -6.6429 243.509 25.921L251.102 117.031C253.759 147.516 259.454 147.689 264.009 117.377L270.463 73.5544C271.602 66.2795 276.917 65.067 282.231 71.1294L283.75 72.6883C287.167 76.3258 291.722 76.1526 294.759 72.1687L296.278 70.2634C300.074 64.7206 305.389 65.067 308.046 71.1294L312.222 81.0025C316.778 91.9149 322.472 91.5685 324.75 80.1365L331.963 45.3208"
                stroke="#0D73DC"
                strokeWidth={2}
                variants={pathVariants}
                initial="hidden"
                animate="visible"
              />
              <circle cx="6.5" cy="70.5" r="6.5" fill="#0D73DC" />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};

export default WaveFormOne;
