import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonModal,
  useIonToast,
  IonGrid,
  IonSpinner,
} from '@ionic/react';
import AvatarCropper from '../onboarding/components/AvatarCropper';
import { Profile, Profile as UserProfile } from '../../@types/app-types';
import { Fragment, useEffect, useState } from 'react';
import { useProfileService } from '../../services/profile.service';
import { motion } from 'framer-motion';
import { fadeIn } from '../../animation';
import TextInput from '../../components/TextInput';
import { useAuth } from '../../auth/services/auth.services';
import { downloadImage, uploadAvatar } from '../../helpers/supabase/utils';
import QuestionUrl from '../../components/question-url';
import Button from '../../components/Button';
import { useAppSelector } from '../../store/store';
import { UserIdentity } from '@supabase/supabase-js';

const AccountSettings: React.FC = () => {
  const { user, profile } = useAppSelector((state) => state.auth);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();
  const { updateProfile, getUserProfile } = useProfileService();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [saving, setSaving] = useState<boolean | string>(false);
  const auth = useAuth();
  const [present, dismiss] = useIonToast();
  const [loading, setLoading] = useState(true);
  const [googleUser, setGoogleUser] = useState(false);
  const [photo, setPhoto] = useState<string | undefined>();
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [questionUrl, setQuestionUrl] = useState('');
  const [urlValid, setUrlValid] = useState(true);

  useEffect(() => {
    if (profile) {
      handleProfile(profile);
    }
  }, [JSON.stringify(profile)]);

  const handleProfile = async (profile: Profile) => {
    if (!user?.email) return;
    try {
      // handle a failure here gracefully if the image is not found
      try {
        const photoURL = await downloadImage(profile.avatarMediaLocation);
        setPhoto(photoURL);
      } catch (error) {
        console.error('Error downloading photo', error);
      }
      setUserProfile(profile);
      setFirstName(profile.firstName || '');
      setLastName(profile.lastName || '');
      setEmail(user.email);
      setQuestionUrl(profile.questionUrl || '');
      if (
        user.identities &&
        user.identities.find((identity: UserIdentity) => {
          return identity.provider === 'google';
        })
      ) {
        setGoogleUser(true);
      }
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (key: string) => {
    // let profilePartial: Partial<UserProfile> = {};
    let profilePartial: any = {};

    switch (key) {
      case 'firstName':
        profilePartial[key] = firstName;
        break;
      case 'lastName':
        profilePartial[key] = lastName;
        break;
      case 'questionUrl':
        if (!urlValid && userProfile?.questionUrl !== questionUrl) {
          present({
            buttons: [{ text: 'Close', handler: () => dismiss() }],
            header: 'Cannot change url',
            message: `This url already exists, please choose a new one`,
            color: 'danger',
            duration: 5000,
          });
          return;
        }
        profilePartial[key] = questionUrl;
        break;
      default:
        break;
    }
    try {
      setSaving(key);
      if (userProfile && Object.keys(profilePartial).length > 0) {
        await updateProfile(userProfile?.id, profilePartial);
        if (user?.id) {
          await getUserProfile(user.id);
        }
      }
    } catch (error) {
      console.error(error);
      if ((error as any).message?.includes('unique')) {
        present({
          buttons: [{ text: 'Close', handler: () => dismiss() }],
          header: 'Cannot change url',
          message: `This url already exists, please choose a new one`,
          color: 'danger',
          duration: 5000,
        });
      }
    } finally {
      setSaving(false);
    }
  };

  const handleUpdateEmail = async () => {
    try {
      if (email !== user?.email) {
        setSaving('email');
        if (user) {
          await auth.updateAuth({ email });
          if (userProfile) {
            await updateProfile(userProfile?.id, { email });
            await getUserProfile(user.id);
          }
        }
      }
    } catch (error) {
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: 'Email must be unique',
        header: 'Error Changing Email',
        duration: 5000,
      });
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      setSaving('password');
      if (password.length < 6) {
        present({
          buttons: [{ text: 'Close', handler: () => dismiss() }],
          message: 'Password must be more than 6 characters.',
          header: 'Error changing password',
          duration: 5000,
        });
      } else {
        await auth.updateAuth({ password });
        setPassword('');
      }
    } catch (error: any) {
      console.error(error);
      present({
        buttons: [{ text: 'Close', handler: () => dismiss() }],
        message: error.message || 'Please try again',
        header: 'Error changing password',
        duration: 5000,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleNewImage = async (image: string) => {
    if (!user?.id) return;
    try {
      setPhotoModalOpen(false);
      setPhoto(image);
      const filePath = await uploadAvatar(image || '/defaultAvatar.png');
      await updateProfile(user.id, { avatarMediaLocation: filePath });
      await getUserProfile(user.id);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IonPage>
      <IonHeader className="bg-white">
        <IonToolbar>
          <IonTitle className="text-black">Settings</IonTitle>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/profile" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid fixed>
          {!loading && (
            <motion.div variants={fadeIn} className="w-full px-4">
              <div className="flex justify-center pt-4">
                <img
                  src={photo || ''}
                  width="120"
                  height="120"
                  alt="profile"
                  className="h-[120px] w-[120px] rounded-full ring-4 ring-blue-400 ring-offset-4"
                  onClick={() => setPhotoModalOpen(true)}
                />
              </div>
              <div className="mb-8">
                <div className="flex">
                  <div className="flex grow">
                    <TextInput
                      className="w-full"
                      label="First name"
                      name="first"
                      type="text"
                      placeholder="Your first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="flex shrink items-end">
                    <Button
                      className="ml-4 h-[40px] w-[4.5rem]"
                      onClick={() => handleUpdate('firstName')}
                      text={
                        saving && saving === 'firstName' ? (
                          <div className="flex h-full w-full items-center justify-center">
                            <IonSpinner className="h-4 w-4" color="light" />
                          </div>
                        ) : (
                          'Save'
                        )
                      }
                    ></Button>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex grow">
                    <TextInput
                      className="w-full"
                      label="Last name"
                      name="last"
                      type="text"
                      placeholder="Your last name"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </div>
                  <div className="flex shrink items-end">
                    <Button
                      className="ml-4 h-[40px] w-[4.5rem]"
                      onClick={() => handleUpdate('lastName')}
                      text={
                        saving && saving === 'lastName' ? (
                          <div className="flex h-full w-full items-center justify-center">
                            <IonSpinner className="h-4 w-4" color="light" />
                          </div>
                        ) : (
                          'Save'
                        )
                      }
                    ></Button>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex grow">
                    <QuestionUrl
                      questionUrl={questionUrl}
                      handleQuestionUrl={(url) => setQuestionUrl(url)}
                      handleUrlValid={(valid) => setUrlValid(valid)}
                      validateUrl={false}
                    />
                  </div>
                  <div className="flex shrink items-center">
                    <Button
                      className="ml-4 h-[40px] w-[4.5rem]"
                      onClick={() => handleUpdate('questionUrl')}
                      text={
                        saving && saving === 'questionUrl' ? (
                          <div className="flex h-full w-full items-center justify-center">
                            <IonSpinner className="h-4 w-4" color="light" />
                          </div>
                        ) : (
                          'Save'
                        )
                      }
                    ></Button>
                  </div>
                </div>
              </div>
              {!googleUser && (
                <Fragment>
                  <span className="font-xl font-semibold">
                    Authentication Settings
                  </span>
                  <div className="flex flex-col">
                    <div className="flex grow">
                      <TextInput
                        className="w-full"
                        label="Email"
                        name="email"
                        type="text"
                        placeholder="Your Login Email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                    <div className="flex w-full shrink items-end justify-center pt-4">
                      <Button
                        className="h-[40px] w-[4.5rem]"
                        onClick={() => handleUpdateEmail()}
                        text={
                          saving && saving === 'email' ? (
                            <div className="flex h-full w-full items-center justify-center">
                              <IonSpinner className="h-4 w-4" color="light" />
                            </div>
                          ) : (
                            'Save'
                          )
                        }
                      ></Button>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex grow">
                      <TextInput
                        className="w-full"
                        label="Change Password"
                        name="password"
                        placeholder="Your Login Password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                      />
                    </div>
                    <div className="flex w-full shrink items-end justify-center py-4">
                      <Button
                        className="h-[40px] w-[4.5rem]"
                        onClick={() => handleUpdatePassword()}
                        text={
                          saving && saving === 'password' ? (
                            <div className="flex h-full w-full items-center justify-center">
                              <IonSpinner className="h-4 w-4" color="light" />
                            </div>
                          ) : (
                            'Save'
                          )
                        }
                      ></Button>
                    </div>
                  </div>
                </Fragment>
              )}
            </motion.div>
          )}
        </IonGrid>
        <IonModal
          isOpen={photoModalOpen}
          breakpoints={[0.1, 0.5, 1]}
          initialBreakpoint={1}
        >
          <IonContent className="flex flex-col justify-center">
            <AvatarCropper
              initialImage={photo || '/defaultAvatar.png'}
              handleNewImage={handleNewImage}
            />
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default AccountSettings;
