import { IonIcon } from '@ionic/react';

import { ReactElement, useEffect, useState } from 'react';

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  icon?: any;
}

export default function TextInput({
  label,
  onChange,
  icon,
  ...props
}: TextInputProps) {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div className={'relative mt-6 ' + props.className}>
      <input
        readOnly={!loaded}
        id={props.name}
        name={props.name}
        type={props.type}
        defaultValue={props.defaultValue}
        value={props.value}
        className="peer border-1 h-10 w-full rounded-md border-gray-300 text-gray-900 placeholder-transparent focus:outline-none"
        placeholder={props.placeholder}
        onChange={onChange}
        onFocus={props.onFocus}
      />
      <label
        htmlFor={props.name}
        className="absolute left-2 -top-3 bg-white px-1 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-gray-600"
      >
        {label}
      </label>
      {icon && (
        <div className="absolute top-0 right-0 bottom-0 mr-2 flex items-center">
          {icon}
        </div>
      )}
    </div>
  );
}
