// templates/component/ViewMyQuestion.tsx

import React from 'react';
import { Question } from '../../../../@types';
import ModalWrapper from '../../../../components/ModalWrapper';
import MyQuestionUrl from '../../../../components/MyQuestionUrl';
import FullQuestionCard from '../../../../components/FullQuestionCard';
import { useGetQuestionAnswersQuery } from '../../../../store/questions-api-slice';
import AnswerListItem from './AnswerListItem';

interface ViewMyQuestionModalProps {
  question: Question;
  onDismiss: () => void;
}

const ViewMyQuestionModal: React.FC<ViewMyQuestionModalProps> = ({
  question,
  onDismiss,
}) => {
  // hooks
  const { data } = useGetQuestionAnswersQuery(question.id);
  // state

  // refs

  // effects

  // methods
  return (
    <ModalWrapper title="View Question" onDismiss={onDismiss}>
      <div className="p-4">
        <div className="flex flex-col gap-6">
          <FullQuestionCard
            subject={question.subject}
            question={question.question}
            showExpand={true}
          />
          <MyQuestionUrl questionUrl={question.url} showCopy={true} />
          <div className="flex flex-col gap-2">
            <span className="text-xl font-bold">Answers:</span>

            {data?.length ? (
              data?.map((answer) => (
                <AnswerListItem key={answer.id} answer={answer} />
              ))
            ) : (
              <p className="text-center font-semibold text-gray-400">
                There are no answers yet! Share the link above to start getting
                answers to your question.
              </p>
            )}
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ViewMyQuestionModal;
