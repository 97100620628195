import { IonPage, IonContent, IonGrid } from '@ionic/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { downloadAudio } from '../../helpers/supabase/utils';
import { useQuestionService } from '../../services/question.service';
import AudioPlayer from '../Response/components/Player';
import ResponseContent from '../Response/components/response-content';
import { fadeIn } from '../../animation';
import { useAppSelector } from '../../store/store';

const ViewResponse = () => {
  const { questionId } = useParams<{ questionId: string }>();
  const { getQuestion } = useQuestionService();
  // const question = useSelector((state) =>
  //   selectQuestionById(state, questionId),
  // );
  const question = useAppSelector((state) => state.questions.currentQuestion);
  const [audioFile, setAudioFile] = useState<any>(null);
  const [showAudioReminder, setShowAudioReminder] = useState<boolean>(false);

  useEffect(() => {
    if (
      !question ||
      (question && question.id && question.id.toString() !== questionId)
    ) {
      getQuestion(questionId);
    }
  }, []);

  useEffect(() => {
    if (question?.answers) {
      const answer = question?.answers[0];
      populateAudio(answer.mediaLocation);
    }
  }, [question?.answers]);

  //Effect to show audio reminder after 1 sec
  useEffect(() => {
    const showTimer = setTimeout(() => {
      setShowAudioReminder(true);
    }, 1000);
    return () => clearTimeout(showTimer);
  }, []);

  // Effect to hide audio reminder after 30 sec
  useEffect(() => {
    const hideTimer = setTimeout(() => {
      setShowAudioReminder(false);
    }, 30000);
    return () => clearTimeout(hideTimer);
  }, []);

  const populateAudio = async (filePath: string) => {
    const audio = await downloadAudio(filePath);
    setAudioFile(audio);
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed>
          {question && (
            <div className="rounded-xl px-6 pt-4 pb-12 md:mt-12 md:border md:border-gray-200 md:shadow-lg">
              <motion.div
                className="rounded-lg bg-green-300  text-green-900"
                initial="hidden"
                animate="visible"
                variants={fadeIn}
              >
                <h1 className="py-2 text-center text-sm font-semibold md:text-lg">
                  {/* todo - fix with appropriate code after refactor */}
                  {/* {question.profile.firstName} responded to your question! */}
                </h1>
              </motion.div>
              <ResponseContent question={question} />
              {audioFile && <AudioPlayer url={audioFile} />}
            </div>
          )}
          {/* A reminder that fades in after 3 seconds and disappears after 10. Animate Presence enables exit animations. */}
          <AnimatePresence>
            {showAudioReminder && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={fadeIn}
                key="audio-reminder"
              >
                <p className="px-14 text-center text-sm text-gray-400 md:hidden">
                  Not hearing anything?
                </p>
                <p className="px-14 text-center text-sm font-semibold text-gray-500 md:hidden">
                  Make sure your phone isn't on silent.
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ViewResponse;
