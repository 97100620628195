// templates/component/MyQuestionCard.tsx

import { formatDistance } from 'date-fns';
import { motion } from 'framer-motion';
import React from 'react';
import { Answer, Question } from '../../@types';
import { fadeIn } from '../../animation';
import Button from '../Button';

interface MyQuestionCardProps {
  question: Question;
  handleView: (id: number) => void;
  answer?: Answer;
}

const MyQuestionCard: React.FC<MyQuestionCardProps> = ({
  question,
  handleView,
  answer,
}) => {
  // hooks

  // state

  // refs

  // effects

  // methods
  return (
    <motion.div
      className="flex flex-col gap-4 rounded-lg bg-white py-8 px-6 shadow-lg"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <span className="text-2xl font-bold">{question.subject}</span>
      {answer ? (
        <div>
          <p className="text-gray-500">
            Asked By:{' '}
            <span className="font-bold text-gray-600">
              {question.name ||
                `${question.profiles?.firstName} ${question.profiles?.lastName}`}
            </span>
          </p>
        </div>
      ) : (
        <p className="text-gray-500">
          Created:{' '}
          <span className="font-bold text-gray-600">
            {formatDistance(new Date(question.createdAt), new Date(), {
              addSuffix: true,
            })}
          </span>
        </p>
      )}
      <Button text="View Question" onClick={() => handleView(question.id)} />
    </motion.div>
  );
};

export default MyQuestionCard;
