// templates/component/ViewMyAnswerModal.tsx

import { FC, useEffect, useState } from 'react';
import { Answer } from '../../../../@types';
import DownloadButton from '../../../../components/DownloadButton';
import FullQuestionCard from '../../../../components/FullQuestionCard';
import ModalWrapper from '../../../../components/ModalWrapper';
import { downloadAudio } from '../../../../helpers/supabase/utils';
import AudioPlayer from '../../../Response/components/Player';

interface ViewMyAnswerModalProps {
  answer: Answer;
  onDismiss: () => void;
}

const ViewMyAnswerModal: FC<ViewMyAnswerModalProps> = ({
  answer,
  onDismiss,
}) => {
  // hooks

  // state
  const [audioFile, setAudioFile] = useState<any>(null);

  // refs

  // effects
  useEffect(() => {
    if (answer.mediaLocation) {
      populateAudio(answer.mediaLocation);
    }
  }, [answer.mediaLocation]);

  // methods
  const populateAudio = async (filePath: string) => {
    const audio = await downloadAudio(filePath);
    setAudioFile(audio);
  };

  return (
    <ModalWrapper
      title="View Answer"
      onDismiss={onDismiss}
      endSlot={
        audioFile && (
          <div className="mr-4">
            <DownloadButton path={answer.mediaLocation} iconOnly />
          </div>
        )
      }
    >
      <div className="p-4">
        <div className="flex flex-col gap-6">
          <FullQuestionCard
            subject={answer.questions.subject}
            question={answer.questions.question}
          />
          {audioFile && <AudioPlayer url={audioFile} />}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ViewMyAnswerModal;
