import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { setOnboarding } from '../../../store/onboarding-state';
import EnterName from '../components/enter-name';
import OnboardingContentWrapper from '../components/OnboardingContentWrapper';

const NameOnboarding = () => {
  const { firstName, lastName } = useSelector(
    (state: RootState) => state.onboarding,
  );
  const dispatch = useDispatch();

  const setFirstName = (value: string) => {
    dispatch(setOnboarding({ key: 'firstName', value }));
  };

  const setLastName = (value: string) => {
    dispatch(setOnboarding({ key: 'lastName', value }));
  };

  return (
    <OnboardingContentWrapper title="Get Started" index={0}>
      <EnterName
        setFirstName={setFirstName}
        setLastName={setLastName}
        firstName={firstName}
        lastName={lastName}
        nextRoute="/onboarding/photo-upload"
      />
    </OnboardingContentWrapper>
  );
};

export default NameOnboarding;
