import { IonContent, IonPage, IonProgressBar, IonSpinner } from '@ionic/react';
import {
  MailIcon,
  LockClosedIcon,
  UserIcon,
  TicketIcon,
} from '@heroicons/react/solid';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useProfileService } from '../../services/profile.service';
import { useQuestionService } from '../../services/question.service';
import { downloadImage } from '../../helpers/supabase/utils';
import { useSessionStorage } from '../../services/session-storage.service';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Profile } from '../../@types';

export const API = 'https://us-central1-quicktake-e8d2f.cloudfunctions.net/api';

const QuestionForm: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { getProfileByUrl } = useProfileService();
  const { getQuestion, createQuestion } = useQuestionService();
  const history = useHistory();
  const { getItem } = useSessionStorage();
  const [submitting, setSubmitting] = useState(false);

  const currentQuestion = useSelector(
    (state: RootState) => state.questions.currentQuestion,
  );
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile>();

  useEffect(() => {
    const getExpertProfile = async (id: string) => {
      try {
        setLoading(true);
        const expertProfile = await getProfileByUrl(id);
        if (!expertProfile) throw new Error();
        // adding a try/catch here because this sometimes doesn't work in local dev
        try {
          const photoURL = await downloadImage(
            expertProfile.avatarMediaLocation,
          );
          expertProfile.avatarMediaLocation = photoURL;
        } catch (error) {
          console.error('error getting image', error);
        }
        if (expertProfile) {
          setProfile(expertProfile);
        } else {
          setProfile(undefined);
        }
      } catch (error) {
        console.error('Unable to get profile', error);
      } finally {
        setLoading(false);
      }
    };
    if (id && !loading) {
      getExpertProfile(id);
    }
  }, [id]);

  // check to see if questionId is saved in session storage
  useEffect(() => {
    const questionId = getItem('questionId');
    if (questionId) {
      // getting question and saving to state
      getQuestion(questionId);
    }
  }, []);

  // question found!  populating form with values
  useEffect(() => {
    if (currentQuestion) {
      setValue('email', currentQuestion.email);
      setValue('subject', currentQuestion.subject);
      setValue('question', currentQuestion.question);
      setValue('name', currentQuestion.name);
      if (currentQuestion.shareable) {
        handleToggleChange(currentQuestion.shareable);
      }
    }
  }, [currentQuestion?.id]);

  // Form stuff
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = useForm({ mode: 'onTouched' });

  //Control submission + animations
  const onSubmit = async (data: any) => {
    if (!profile) return;
    try {
      setSubmitting(true);
      const question = await createQuestion({
        email: data.email,
        name: data.name,
        profile_id: profile.id,
        question: data.question,
        subject: data.subject,
      });
      history.push(`/ask/${id}/success/${question.id}`);
    } catch (error) {
      console.error('Unable to submit question', error);
      // other error handling goes here
    } finally {
      setSubmitting(false);
    }
  };

  //Toggle stuff
  //Virtual input that we set using the setValue function from react-hook-form
  //Step 1: Register sharable value
  register('sharable', { required: false });
  //Make sure this initial value matches the initialValue prop of the toggle
  useEffect(() => {
    setValue('sharable', false);
  }, []);
  //Update with state of child component
  const handleToggleChange = (val: boolean) => {
    setValue('sharable', val);
  };

  //Character count stuff
  const maxLength = 500;
  const [charCount, setCharCount] = useState(0);
  const handleQuestionChange = (e: any) => {
    setCharCount(e.target.value.length);
  };

  //Email validation stuff
  const [validEmail, setValidEmail] = useState(false);
  const validateEmail = (e: any) => {
    if (errors.email) {
      setValidEmail(false);
    } else setValidEmail(true);
  };

  //Dynamic talent info

  return (
    <IonPage>
      <IonContent>
        <div className="m-2">
          <div className="relative mx-auto max-w-prose items-center rounded-xl bg-white py-6 px-2 shadow-xl md:mt-11 md:py-8 md:px-6">
            {/* Loading State */}
            {loading && (
              <div className="absolute top-0 left-0 h-full w-full bg-white"></div>
            )}
            {/* Header */}
            <div className="absolute top-0 left-0 right-0 h-3 w-full rounded-t-xl bg-blue-500" />
            <div className="mx-0 flex w-full flex-row items-start justify-between">
              <div className="flex flex-shrink flex-row items-center">
                <div className="flex-shrink-0">
                  <img
                    src={profile?.avatarMediaLocation}
                    className="h-12 w-12 rounded-full border-2 border-blue-500"
                    alt="profile avatar"
                  />
                </div>
                <div className="ml-3">
                  <h1 className="m-0 text-lg font-medium text-black antialiased md:text-2xl">
                    {profile?.firstName} {profile?.lastName}
                  </h1>
                  <p className="text-sm text-gray-500">
                    {' '}
                    Ask me a question, I'll send you a voice reply 💬
                  </p>
                </div>
              </div>
            </div>
            {/* Form Body */}
            <form className="mt-6" onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-6 sm:col-span-6">
                <label
                  htmlFor="about"
                  className="text-md block font-medium text-gray-700"
                >
                  Question
                </label>
                <div
                  className={`relative mt-1 block w-full rounded-t-md rounded-b-sm border border-gray-300 py-1 shadow-sm ${
                    errors?.question
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'focus:border-blue-500 focus:ring-blue-500'
                  }`}
                >
                  <label htmlFor="title" className="sr-only">
                    Subject
                  </label>
                  <input
                    type="text"
                    id="title"
                    className={`block w-full border-0 pt-2 text-lg font-medium placeholder-gray-500 focus:ring-0  ${
                      errors?.subject
                        ? 'border-red-300 text-red-900 placeholder-red-300  focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300'
                    }`}
                    autoFocus={true}
                    placeholder="Subject"
                    maxLength={45}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                    {...register('subject', {
                      required: 'Make sure you have a subject line',
                    })}
                  />
                  <textarea
                    id="about"
                    className={`block h-56 w-full border-0 py-0  focus:ring-0 sm:text-sm md:resize-none  ${
                      charCount < maxLength - 200 && 'resize-none'
                    } `}
                    defaultValue={''}
                    maxLength={maxLength}
                    placeholder="Write your question here..."
                    {...register('question', {
                      required: 'You must ask a question in order to submit',
                      maxLength: {
                        value: maxLength,
                        message: `Your question must be less than ${maxLength} characters`,
                      },
                      onChange: (e) => handleQuestionChange(e),
                    })}
                  />
                  <div
                    className={`absolute bottom-2 right-1 left-auto top-auto text-xs ${
                      charCount > maxLength - 30
                        ? charCount === maxLength
                          ? 'text-red-600'
                          : 'text-yellow-500'
                        : 'text-gray-400'
                    } ${charCount < maxLength - 200 && 'hidden'}`}
                  >
                    <p>
                      {charCount}/{maxLength}
                    </p>
                  </div>
                  <IonProgressBar
                    value={charCount / maxLength}
                    className="absolute bottom-0 top-auto left-0 right-0"
                  ></IonProgressBar>
                </div>
                {errors.question ? (
                  <p className="mt-2 text-sm text-red-600" id="question-error">
                    {errors.question?.message}
                  </p>
                ) : (
                  <p className="mt-2 text-sm text-gray-500">
                    500 character limit
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label
                  htmlFor="name"
                  className="text-md block font-medium text-gray-700"
                >
                  Name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <UserIcon
                      className={`h-5 w-5 ${
                        errors.name
                          ? 'text-red-500'
                          : getValues('name')?.length > 0
                          ? 'text-green-500'
                          : 'text-gray-400'
                      }`}
                      aria-hidden="true"
                    />
                  </div>

                  <input
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter your name"
                    className={`block w-full rounded-md border px-10 focus:outline-none sm:text-sm ${
                      errors?.name
                        ? 'border-red-300 text-red-900 placeholder-red-300  focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300'
                    }`}
                    aria-invalid="true"
                    aria-describedby="name-error"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                  />
                </div>
                {errors.name && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors.name?.message}
                  </p>
                )}
              </div>
              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="text-md block font-medium text-gray-700"
                >
                  Email
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MailIcon
                      className={`h-5 w-5 ${
                        errors.email
                          ? 'text-red-500'
                          : validEmail
                          ? 'text-green-500'
                          : 'text-gray-400'
                      }`}
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                        message: 'Please use a valid email address',
                      },
                      onBlur: (e) => validateEmail(e),
                    })}
                    type="email"
                    name="email"
                    id="email"
                    className={`block w-full rounded-md border px-10 focus:outline-none sm:text-sm ${
                      errors?.email
                        ? 'border-red-300 text-red-900 placeholder-red-300  focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300'
                    }`}
                    placeholder="you@example.com"
                    aria-invalid="true"
                    aria-describedby="email-error"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') e.preventDefault();
                    }}
                  />
                </div>
                {errors.email && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors.email?.message}
                  </p>
                )}
              </div>{' '}
              {/** Removing for now because this doesn't have a use case yet */}
              {/* <div className="my-5">
              <Toggle
                label={'Privacy Option'}
                description={`Would you like to keep this question just between you and ${profile?.firstName}?`}
                handleFormToggle={handleToggleChange}
                initialValue={false}
              />
            </div> */}
              <div className="mt-5">
                <button
                  disabled={
                    !!errors.email ||
                    !!errors.question ||
                    !!errors.subject ||
                    !!errors.message ||
                    !!errors.name
                  }
                  type="submit"
                  className={`group relative flex w-full justify-center rounded-md border border-transparent bg-blue-500 py-2 px-4 text-sm font-medium text-white transition-all hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
                  onClick={() => handleSubmit(onSubmit)}
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockClosedIcon
                      className="h-5 w-5 text-blue-200 transition-colors group-hover:text-blue-400"
                      aria-hidden="true"
                    />
                  </span>
                  {submitting
                    ? 'Submitting'
                    : `Submit question to ${profile?.firstName} ${profile?.lastName}`}
                  {submitting && (
                    <IonSpinner
                      name="crescent"
                      color="white"
                      className="ml-2 h-5 w-5"
                    />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default QuestionForm;
