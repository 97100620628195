// templates/component/AnswerMyQuestion.tsx

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import PageWrapper from '../../components/PageWrapper';
import {
  useAnswerQuestionAsGuestMutation,
  useAnswerQuestionWithAccountMutation,
  useGetQuestionToAnswerQuery,
} from '../../store/questions-api-slice';
import { uploadAudio } from '../../helpers/supabase/utils';
import { useIonToast } from '@ionic/react';
import MultiComponentSlider from '../../components/MultiComponentSlider';
import AnswerQuestionAudioStep from './components/AnswerQuestionAudioStep';
import { FormProvider, useForm } from 'react-hook-form';
import AnswerQuestionAccountTypeStep from './components/AnswerQuestionAccountTypeStep';
import AnswerQuestionAccountStep from './components/AnswerQuestionAccountStep';
import { useProfileService } from '../../services/profile.service';
import { Profile } from '../../@types';

interface AnswerMyQuestionProps {}

const AnswerMyQuestion: React.FC<AnswerMyQuestionProps> = () => {
  // hooks
  const { expertUrl, questionUrl } = useParams() as {
    expertUrl: string;
    questionUrl: string;
  };
  const [present] = useIonToast();
  const { data } = useGetQuestionToAnswerQuery({ expertUrl, questionUrl });
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      filePath: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      accountType: 'guest',
    },
  });
  const [
    answerAsGuest,
    { isLoading: guestLoading, isSuccess: guestSuccess, isError: guestError },
  ] = useAnswerQuestionAsGuestMutation();
  const [
    answerWithAccount,
    {
      isLoading: accountLoading,
      isSuccess: accountSuccess,
      isError: accountError,
      error: accountErrorMsg,
      data: accountData,
    },
  ] = useAnswerQuestionWithAccountMutation();
  const history = useHistory();
  const { getUserProfile } = useProfileService();
  // state
  const [uploadingAudio, setUploadingAudio] = useState(false);
  const [[page, direction], setPage] = useState([0, 0]);
  // refs

  // effects
  useEffect(() => {
    if (!guestLoading) {
      if (guestSuccess) {
        present({
          message: 'Answer submitted successfully!',
          duration: 2500,
        });

        history.push(`${history.location.pathname}/success`);
      }
      if (guestError) {
        present({
          message: 'Error saving answer, please try again',
          duration: 2500,
        });
      }
    }
  }, [guestLoading, guestSuccess, guestError]);

  useEffect(() => {
    if (!accountLoading) {
      if (accountSuccess) {
        present({
          message: 'Answer submitted successfully!',
          duration: 2500,
        });
        if (accountData) {
          handleCreateAccountSuccess(accountData.profile as any);
        }
      }
      if (accountError) {
        present({
          message: `Error saving answer.  ${(accountErrorMsg as any)?.message}`,
          duration: 2500,
        });
      }
    }
  }, [accountLoading, accountSuccess, accountError]);

  // methods
  const paginate = (newDirection: number) => {
    const newPage = page + newDirection;
    setPage([newPage, newDirection]);
  };

  const handleUpload = async (url: string) => {
    // upload happens in background while we handle account creation
    setUploadingAudio(true);
    try {
      const filePath = await uploadAudio(url);
      methods.setValue('filePath', filePath);
    } catch (error) {
      present({
        message: 'Error saving audio, please try again.',
        duration: 2500,
      });
    } finally {
      setUploadingAudio(false);
      paginate(1);
    }
  };

  const handleSubmit = (shouldLogin = false) => {
    if (methods.getValues('accountType') === 'guest') {
      continueAsGuest();
    } else {
      createAccount(shouldLogin);
    }
  };

  const continueAsGuest = () => {
    // todo wait for upload to finish...
    const { firstName, lastName, filePath } = methods.getValues();
    answerAsGuest({
      guest: {
        first_name: firstName,
        last_name: lastName,
      },
      answer: {
        media_location: filePath,
        question_id: data.id,
      },
    });
  };

  const handleCreateAccountSuccess = async (profile: Profile) => {
    await getUserProfile(profile.id);
    history.push(`${history.location.pathname}/success`);
  };

  const createAccount = (shouldLogin: boolean) => {
    const { firstName, lastName, email, password, filePath } =
      methods.getValues();
    answerWithAccount({
      account: {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      },
      answer: {
        media_location: filePath,
        question_id: data.id,
      },
      shouldLogin,
    });
  };

  const pages = [
    <AnswerQuestionAudioStep
      question={data}
      handleUpload={handleUpload}
      uploadingAudio={uploadingAudio}
    />,
    <AnswerQuestionAccountTypeStep handleContinue={() => paginate(1)} />,
    <AnswerQuestionAccountStep handleSubmit={handleSubmit} />,
  ];

  return (
    <PageWrapper title="Answer Question">
      {data && (
        <FormProvider {...methods}>
          <MultiComponentSlider
            pages={pages}
            page={page}
            direction={direction}
          />
        </FormProvider>
      )}
    </PageWrapper>
  );
};

export default AnswerMyQuestion;
