import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const PhotoUpload: React.FC<{ handleClick?: () => void; photo: string }> = ({
  handleClick,
  photo,
}) => {
  // Only start the waveform animation when it is in the viewport
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 1 });
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);

  const pathVariants = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 2,
      },
    },
  };

  const circleVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 2,
      },
    },
  };
  return (
    <>
      <div className="mb-8 flex w-full justify-center">
        <svg
          width="337"
          height="127"
          viewBox="0 0 337 127"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          ref={ref}
        >
          <g>
            <motion.path
              d="M-1.5 59.776C2.2963 42.9744 7.69435 50.9422 10.7314 67.7438L15.287 91.8203C19.0833 112.952 24.7777 112.952 28.1944 91.6471L31.611 68.6098C35.7869 40.7226 41.4814 40.8959 44.5185 68.783L46.4166 85.4114C50.2129 120.574 55.9073 120.574 59.3239 85.4114L63.1203 46.7851C66.9166 8.50514 72.611 8.67836 76.0277 46.7851L80.2036 93.206C83.9999 135.989 89.6944 135.989 93.1111 93.206L98.0462 33.1013C101.463 -9.33572 107.157 -9.50893 110.954 33.1013L115.129 78.4829C116.648 95.2845 122.343 95.631 128.037 79.5222L132.592 65.6652C135.63 56.6582 141.324 56.485 145.12 65.3188L149.676 74.8455C153.092 82.8132 158.407 82.8132 161.824 74.8455L164.861 67.5706C167.139 62.3742 172.454 61.6813 176.25 66.1849L180.805 71.208C183.842 74.4991 188.398 74.4991 191.435 71.208L194.092 68.2634C199.028 62.5474 204.342 63.5867 205.861 70.5152L211.935 99.2684C216.491 121.266 222.185 121.093 224.842 98.922L231.676 35.6995C234.713 8.50515 240.407 8.50514 244.583 35.5263L249.898 71.3812C252.176 86.4507 257.87 86.7971 262.805 72.0741L266.602 60.6421C269.259 52.6743 274.574 52.3279 278.75 59.776L282.926 67.5706C285.963 72.7669 290.898 72.9401 294.694 68.0902C298.491 62.5474 303.805 62.8938 306.463 68.9563L311.778 81.4275C315.574 90.9542 321.268 90.781 324.305 80.9079L329.241 64.6259"
              stroke="#0D73DC"
              strokeWidth="2"
              strokeMiterlimit="10"
              variants={pathVariants}
              initial="hidden"
              animate={controls}
            />
            <motion.circle
              cx="330.5"
              cy="60.5"
              r="6.5"
              fill="#0D73DC"
              initial="hidden"
              animate={controls}
              variants={circleVariants}
            />
          </g>
        </svg>
      </div>
      {/* Camera icon */}
      {photo === '/defaultAvatar.png' ? (
        <motion.svg
          width="153"
          height="153"
          viewBox="0 0 153 153"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          whileTap={{ scale: 0.9 }}
          whileHover={{ scale: 1.1 }}
          onClick={handleClick}
        >
          <ellipse cx="76.5" cy="75" rx="61.5" ry="60" fill="#2563eb" />
          <path
            d="M86.1997 76.6788C86.1997 81.9095 81.9594 86.1497 76.7287 86.1497C71.4981 86.1497 67.2578 81.9095 67.2578 76.6788C67.2578 71.4482 71.4981 67.2079 76.7287 67.2079C81.9594 67.2079 86.1997 71.4482 86.1997 76.6788Z"
            fill="white"
            stroke="white"
          />
          <path
            d="M93.0357 56.2256L93.0617 56.2666L93.0951 56.3019C93.6015 56.8362 94.3034 57.2369 94.9568 57.2369H104.148C105.999 57.2388 107.772 57.9747 109.081 59.2831C110.389 60.5914 111.125 62.3654 111.127 64.2156V94.1274C111.125 95.9777 110.389 97.7516 109.081 99.06C107.772 100.368 105.999 101.104 104.148 101.106H49.3092C47.4589 101.104 45.6849 100.368 44.3766 99.06C43.0683 97.7517 42.3324 95.9778 42.3304 94.1276V64.2247C42.3324 62.3745 43.0683 60.6007 44.3766 59.2924C45.6849 57.9841 47.4589 57.2482 49.3092 57.2463H49.9318H50.4318V56.7463V55.4999C50.4318 54.9714 50.6418 54.4645 51.0155 54.0908C51.3892 53.7171 51.8961 53.5072 52.4246 53.5072H56.1637C56.6922 53.5072 57.199 53.7171 57.5727 54.0908C57.9464 54.4645 58.1564 54.9714 58.1564 55.4999V56.7463V57.2463H58.6564C59.0423 57.2463 59.3861 57.0957 59.661 56.9182C59.9382 56.7393 60.1785 56.5116 60.3678 56.3053L60.398 56.2724L60.4219 56.2346L64.4632 49.847L64.4635 49.8466C64.5142 49.7663 64.5708 49.6899 64.6328 49.618L64.6337 49.6169C65.941 48.0922 67.6921 47.266 69.5621 47.266H83.8953C85.7653 47.266 87.5164 48.0922 88.8237 49.6169L88.8246 49.618C88.8866 49.6899 88.9432 49.7663 88.9939 49.8466L88.9944 49.8473L93.0357 56.2256ZM76.7287 92.1352H76.7293C80.827 92.1305 84.7555 90.5006 87.653 87.6031C90.5505 84.7056 92.1804 80.7771 92.1851 76.6794V76.6788C92.1851 73.6218 91.2786 70.6335 89.5802 68.0917C87.8819 65.5499 85.4679 63.5688 82.6436 62.3989C79.8193 61.2291 76.7116 60.923 73.7133 61.5194C70.7151 62.1158 67.961 63.5878 65.7994 65.7495C63.6378 67.9111 62.1657 70.6651 61.5693 73.6634C60.9729 76.6616 61.279 79.7694 62.4489 82.5937C63.6187 85.418 65.5998 87.8319 68.1416 89.5303C70.6834 91.2287 73.6717 92.1352 76.7287 92.1352Z"
            fill="white"
            stroke="white"
          />
        </motion.svg>
      ) : (
        <img
          src={photo}
          width="120"
          height="120"
          alt="profile"
          className="rounded-full ring-4 ring-blue-400 ring-offset-4"
          onClick={handleClick}
        />
      )}
    </>
  );
};

export default PhotoUpload;
