// templates/component/MultiFormSlider.tsx

import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { sliderAnimation } from '../../animation';

interface MultiFormSliderProps {
  pages: JSX.Element[];
  page: number;
  direction: number;
}

const MultiComponentSlider: React.FC<MultiFormSliderProps> = ({
  pages,
  page,
  direction,
}) => {
  // hooks

  // state

  // refs

  // effects

  // methods

  return (
    <AnimatePresence initial={false} custom={sliderAnimation} exitBeforeEnter>
      <motion.div
        key={page}
        custom={direction}
        variants={sliderAnimation}
        initial="enter"
        animate="center"
        exit="exit"
        className="h-full overflow-auto"
      >
        {pages[page]}
      </motion.div>
    </AnimatePresence>
  );
};

export default MultiComponentSlider;
