import { IonGrid, IonPage } from '@ionic/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { slideUpFromBottom } from '../../../animation';
import Button from '../../../components/Button';

import './index.css';

const GetStarted: React.FC = () => {
  return (
    <IonPage className="blue-gradient">
      <IonGrid fixed>
        <motion.div
          className="wrapper mx-12"
          initial="hidden"
          animate="visible"
          variants={slideUpFromBottom}
        >
          <img
            src={'./QuickTakeLogo.png'}
            className="mx-auto px-4"
            alt={'QuickTake Logo'}
          />
          <Link to="/sign-up">
            <Button
              text="Sign up"
              textColor="text-blue-800"
              bgColor="bg-white"
              hoverColor="hover:bg-gray-200"
              className="mt-24"
            />
          </Link>
          <Link to="/login">
            <Button
              text="Login"
              textColor="text-white"
              bgColor="bg-blue-800"
              hoverColor="hover:bg-blue-900"
              className="mt-4"
            />
          </Link>
        </motion.div>
      </IonGrid>
    </IonPage>
  );
};

export default GetStarted;
