const OnboardingProgress: React.FC<{ index: number }> = ({ index }) => {
  const steps = Array.from(Array(5).keys());
  return (
    <div className="mt-6 flex items-center justify-center">
      {steps.map((num) => {
        if (num === index) {
          return (
            <div
              className="p m-2 h-3.5 w-3.5 rounded-full bg-blue-600"
              key={num}
            ></div>
          );
        }
        return (
          <div
            className="m-2 h-3 w-3 rounded-full border-2 border-blue-300 bg-blue-300"
            key={num}
          ></div>
        );
      })}
    </div>
  );
};

export default OnboardingProgress;
