import { IonIcon, useIonToast } from '@ionic/react';
import { motion } from 'framer-motion';
import { linkOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Profile } from '../../@types/app-types';
import { fadeIn } from '../../animation';
import { copyToClipboard } from '../../helpers/misc';

const QuestionZeroState = ({
  profile,
  heading,
  subheading,
}: {
  profile: Profile;
  heading: string;
  subheading: string;
}) => {
  const [present, dismiss] = useIonToast();
  const [questionUrl, setQuestionUrl] = useState<string | undefined>();
  useEffect(() => {
    setQuestionUrl(`${window.location.origin}/ask/${profile.questionUrl}`);
  }, [profile.questionUrl]);

  const handleCopyLink = () => {
    if (profile && questionUrl) {
      copyToClipboard(questionUrl);
      present({
        message: '✅  Link copied. Share it far and wide!',
        duration: 3000,
      });
    }
  };
  return (
    <motion.div
      className="mt-24 flex w-full flex-col items-center px-8 text-gray-400"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <h2 className=" text-2xl font-bold">
        {profile.firstName}, {heading}
      </h2>
      <p className="mt-8 text-center font-semibold">{subheading}</p>

      <p className="mt-8 select-all text-center font-semibold text-gray-800 underline">
        {questionUrl}
      </p>

      <motion.div
        className=" space-between mt-8 flex items-center justify-center rounded-full bg-blue-600 px-4 py-2 shadow-xl"
        onClick={() => handleCopyLink()}
        whileTap={{ scale: 0.9 }}
      >
        <IonIcon icon={linkOutline} className="mr-2 text-white"></IonIcon>
        <a className="font-semibold text-white">Copy my link</a>
      </motion.div>
    </motion.div>
  );
};

export default QuestionZeroState;
