import { IonContent, IonGrid, IonIcon, IonPage } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import { useHistory } from 'react-router';
import OnboardingProgress from '../OnboardingProgress';

const OnboardingContentWrapper: React.FC<{
  title: string;
  index: number;
  showBack?: boolean;
}> = ({ title, children, index, showBack = false }) => {
  const history = useHistory();
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed className="min-h-full">
          <div className="flex h-full flex-col pt-4">
            <div className="flex w-full items-center justify-between font-semibold text-gray-700">
              <div
                id="right"
                className="flex w-3/12 items-center justify-start"
              >
                {showBack && (
                  <IonIcon
                    icon={chevronBack}
                    onClick={() => history.goBack()}
                    role="button"
                    size="large"
                  />
                )}
              </div>

              <div id="center" className="w-6/12 text-center">
                <h2 className="m-0">{title}</h2>
              </div>
              <div id="right" className="w-3/12 text-right"></div>
            </div>
            {children}
            <OnboardingProgress index={index} />
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default OnboardingContentWrapper;
