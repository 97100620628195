import { Route, useHistory } from 'react-router-dom';
import { IonRouterOutlet, setupIonicReact } from '@ionic/react';
import Tabs from './Tabs';
import { useEffect, useState } from 'react';
import SignUp from './auth/pages/SignUp';
import LogIn from './auth/pages/LogIn';
import GetStarted from './auth/pages/GetStarted';
import { useSelector } from 'react-redux';
import { useAuthState } from './auth/services/auth.services';
import QuestionForm from './pages/QuestionForm';
import Onboarding from './pages/onboarding';

import './App.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { LoadingState } from './@types/app-types';
import Success from './pages/QuestionForm/Success';
import ForgotPassword from './auth/pages/forgot-password';
import ResetPassword from './auth/pages/reset-password';
import ViewResponse from './pages/view-response';
import GoogleRedirect from './auth/pages/google-redirect';

//Google Analytics
import ReactGA from 'react-ga4';
import { useAppSelector } from './store/store';
import AnswerMyQuestion from './pages/AnswerMyQuestion';
import AnswerQuestionSuccess from './pages/AnswerMyQuestion/AnswerQuestionSuccess';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID as string);

setupIonicReact({
  mode: 'ios',
});

const ProtectedRoute: React.FC = ({ children }) => {
  const { user, authLoading } = useAppSelector((state) => state.auth);
  const history = useHistory();
  const [canRender, setCanRender] = useState<boolean>(false);
  useEffect(() => {
    if (authLoading === LoadingState.LOADED) {
      if (user) {
        setCanRender(true);
      } else {
        history.push('/get-started');
      }
    }
  }, [user, authLoading]);

  return <>{canRender && children}</>;
};

/**
 * Handles routes without the tab bar (before authentication)
 */
const App: React.FC = () => {
  useAuthState();

  return (
    <IonRouterOutlet>
      <ProtectedRoute>
        <Route path="/onboarding">
          <Onboarding />
        </Route>
        <Route path="/">
          <Tabs />
        </Route>
      </ProtectedRoute>
      <Route path="/get-started">
        <GetStarted />
      </Route>
      <Route path="/sign-up">
        <SignUp />
      </Route>
      <Route path="/login">
        <LogIn />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/question/:id">
        <QuestionForm />
      </Route>
      <Route exact path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/google-redirect">
        <GoogleRedirect />
      </Route>
      <Route exact path="/answer/:expertId/:questionId">
        <ViewResponse />
      </Route>

      <Route path="/ask/:id" exact>
        <QuestionForm />
      </Route>
      <Route exact path="/ask/:id/success/:question">
        <Success />
      </Route>
      <Route exact path="/ask/:expertUrl/:questionUrl">
        <AnswerMyQuestion />
      </Route>
      <Route exact path="/ask/:expertUrl/:questionUrl/success">
        <AnswerQuestionSuccess />
      </Route>
    </IonRouterOutlet>
  );
};

export default App;
