// templates/component/AnswerListItem.tsx

import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Answer } from '../../../../../@types';
import DownloadButton from '../../../../../components/DownloadButton';
import { downloadAudio } from '../../../../../helpers/supabase/utils';
import AudioPlayer from '../../../../Response/components/Player';

interface AnswerListItemProps {
  answer: Answer;
}

const AnswerListItem: React.FC<AnswerListItemProps> = ({ answer }) => {
  // hooks
  const [audioFile, setAudioFile] = useState<any>(null);

  // state

  // refs

  // effects
  useEffect(() => {
    if (answer.mediaLocation) {
      populateAudio(answer.mediaLocation);
    }
  }, [answer.mediaLocation]);

  // methods
  const populateAudio = async (filePath: string) => {
    const audio = await downloadAudio(filePath);
    setAudioFile(audio);
  };

  return (
    <div className="flex items-center gap-2 rounded-md bg-white px-4 py-6">
      <div className="flex w-full grow flex-col">
        {answer.profiles
          ? `${answer.profiles.firstName} ${answer.profiles.lastName}`
          : answer.guests
          ? `${answer.guests.firstName} ${answer.guests.lastName}`
          : ''}
        <span>{format(new Date(answer.createdAt), 'Pp')}</span>
      </div>
      {audioFile && <AudioPlayer url={audioFile} singleLine={true} />}
      <DownloadButton path={answer.mediaLocation} iconOnly={true} />
    </div>
  );
};

export default AnswerListItem;
