import { IonPage, IonRouterOutlet } from '@ionic/react';
import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';
import 'swiper/css/pagination';
import './index.css';
import { useDispatch } from 'react-redux';
import NameOnboarding from './NameOnboarding';
import PhotoUploadOnboarding from './PhotoUploadOnboarding';
import QuestionUrlOnboarding from './QuestionUrlOnboarding';
import ReviewOnboarding from './ReviewOnboarding';
import { useSessionStorage } from '../../services/session-storage.service';
import { setOnboarding } from '../../store/onboarding-state';

const Onboarding: React.FC = () => {
  const { getItem } = useSessionStorage();
  const dispatch = useDispatch();

  useEffect(() => {
    retrieveFromLocalStorage();
  }, []);

  const retrieveFromLocalStorage = () => {
    const firstName = getItem('firstName');

    if (firstName) {
      dispatch(setOnboarding({ key: 'firstName', value: firstName }));
    }
    const lastName = getItem('lastName');
    if (lastName) {
      dispatch(setOnboarding({ key: 'lastName', value: lastName }));
    }
    const photo = getItem('photo');
    if (photo) {
      dispatch(setOnboarding({ key: 'photo', value: photo }));
    }
    const questionUrl = getItem('questionUrl');
    if (questionUrl) {
      dispatch(setOnboarding({ key: 'questionUrl', value: questionUrl }));
    }
  };

  return (
    <IonPage>
      <IonRouterOutlet>
        <Route exact path="/onboarding/name">
          <NameOnboarding />
        </Route>
        <Route exact path="/onboarding/photo-upload">
          <PhotoUploadOnboarding />
        </Route>
        <Route exact path="/onboarding/question-url">
          <QuestionUrlOnboarding />
        </Route>
        <Route exact path="/onboarding/review">
          <ReviewOnboarding />
        </Route>
        <Redirect exact from="/onboarding" to="/onboarding/name" />
      </IonRouterOutlet>
    </IonPage>
  );
};

export default Onboarding;
