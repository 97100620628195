// templates/component/MyQuestionsZeroState.tsx

import { IonIcon } from '@ionic/react';
import { motion } from 'framer-motion';
import { linkOutline } from 'ionicons/icons';
import { FC } from 'react';
import { Profile } from '../../@types';
import { fadeIn } from '../../animation';
import Button from '../Button';
import questionUrl from '../question-url';

interface MyQuestionsZeroStateProps {
  profile: Profile;
  heading: string;
  subheading: string;
  actionText?: string;
  actionFn?: () => void;
}

const MyQuestionsZeroState: FC<MyQuestionsZeroStateProps> = ({
  profile,
  heading,
  subheading,
  actionText,
  actionFn,
}) => {
  // hooks

  // state

  // refs

  // effects

  // methods
  return (
    <motion.div
      className="flex w-full flex-col items-center gap-8 px-8 text-gray-400"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <h2 className=" text-2xl font-bold">
        {profile?.firstName}, {heading}
      </h2>
      <p className="text-center font-semibold">{subheading}</p>

      {actionText && <Button text={actionText} onClick={actionFn} />}
    </motion.div>
  );
};

export default MyQuestionsZeroState;
