import { IonIcon } from '@ionic/react';
import {
  FieldError,
  RegisterOptions,
  UseFormRegister,
  FieldValues,
  ValidationRule,
} from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';

interface InputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  icon?: any;
  isPassword?: boolean;
  rules?: any;
  control?: any;
  includeForgot?: boolean;
  error?: FieldError;
  register: any;
  isRequired?: string | ValidationRule<boolean> | undefined;
  hasPattern?: {
    value: any;
    message: string;
  };
}

//TODO: Make error reset onBlur or onChange
const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  icon,
  rules,
  control,
  includeForgot = false,
  error,
  register,
  isRequired,
  hasPattern,
  ...props
}) => {
  const inputRef = useRef(null);
  // workaround to avoid weird autocomplete in chrome
  const [focused, setFocused] = useState(false);
  useEffect(() => {
    setFocused(true);
  }, []);
  return (
    <div className="relative mt-6 rounded-md text-black">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <IonIcon icon={icon} className="text-gray-400" />
      </div>
      {/* Input field with conditional padding for an icon on left and forgot password link on the right */}
      {/* readOnly property added to get around autocomplete issues. Focus sets readOnly to false */}
      <input
        readOnly={!focused}
        type={props.type}
        className={`border-1 peer h-10 w-full rounded-md border-gray-300 text-gray-900 placeholder-transparent focus:outline-none 
            ${includeForgot && 'pr-16'} 
            ${icon ? 'pl-8' : 'pl-2'}
            ${error ? 'border-red-500' : 'border-gray-300'}
          `}
        placeholder={props.placeholder}
        {...register(name, {
          required: isRequired,
          pattern: hasPattern,
        })}
        id={name}
        autoFocus={props.autoFocus}
        onFocus={(e) => e.stopPropagation()}
      />
      <label
        htmlFor={name}
        className={`absolute -top-3 px-1 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3 peer-focus:bg-white peer-focus:text-sm peer-focus:text-gray-600 ${
          icon
            ? 'left-2 peer-placeholder-shown:left-8 peer-focus:left-2'
            : 'left-2'
        } ${focused ? 'bg-blue-50' : ''}`}
      >
        {label}
      </label>
      {includeForgot && (
        <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center pl-1">
          <span className="font-semibold text-blue-500 sm:text-sm">
            Forgot?
          </span>
        </div>
      )}
      {error && (
        <p className=" absolute -bottom-3 left-2 ml-2 bg-white px-1 text-sm text-red-600">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default InputField;

//Check out this code sandbox to understand passing form props around with react-hooks-form
//See https://codesandbox.io/s/react-hook-form-nested-fields-9xhg0?file=/src/index.js:598-617
