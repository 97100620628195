import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import { useSwiper } from 'swiper/react';
import { fadeIn } from '../../../../animation';
import Button from '../../../../components/Button';
import PageTitle from '../../../../components/PageTitle';
import TextInput from '../../../../components/TextInput';
import WaveFormOne from '../WaveFormOne';

const EnterName: React.FC<{
  setFirstName: (name: string) => void;
  setLastName: (name: string) => void;
  firstName: string;
  lastName: string;
  nextRoute: string;
}> = ({ setFirstName, setLastName, firstName, lastName, nextRoute }) => {
  const history = useHistory();
  return (
    <div className="flex flex-col items-center">
      <PageTitle title="What's your name?" className="text-center text-black" />
      <div className="flex justify-center">
        <WaveFormOne />
      </div>
      <motion.div variants={fadeIn} className="w-full px-8">
        {/* <InputField label="First name" name="first" type="text" /> */}
        <TextInput
          label="First name"
          name="first"
          type="text"
          placeholder="Your first name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextInput
          label="Last name"
          name="last"
          type="text"
          placeholder="Your last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </motion.div>
      <div className="w-4/5 pt-8 pb-4">
        <Button
          text="Continue"
          disabled={!firstName || !lastName}
          onClick={() => history.push(nextRoute)}
        />
      </div>
    </div>
  );
};

export default EnterName;
