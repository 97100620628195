// templates/component/AskedQuestions.tsx

import { profile } from 'console';
import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { LoadingState, Question } from '../../../../@types';
import QuestionCard from '../../../../components/question-card';
import QuestionLoading, {
  wrapperVariants,
} from '../../../../components/question-loading';
import QuestionZeroState from '../../../../components/question-zero-state';
import { useAppSelector } from '../../../../store/store';

interface AskedQuestionsProps {
  handleViewAskedQuestion: (question: Question) => void;
}

const AskedQuestions: FC<AskedQuestionsProps> = ({
  handleViewAskedQuestion,
}) => {
  // hooks
  const { profile } = useAppSelector((state) => state.auth);
  const questionsLoading = useAppSelector(
    (state) => state.questions.questionsLoading,
  );
  const questions = useAppSelector((state) => state.questions.questions);

  // state
  const [loading, setLoading] = useState<boolean>(true);

  // refs

  // effects
  useEffect(() => {
    if (questionsLoading === LoadingState.LOADING) {
      setLoading(true);
    }
    if (
      questionsLoading === LoadingState.LOADED ||
      questionsLoading === LoadingState.INIT
    ) {
      setLoading(false);
    }
  }, [questionsLoading]);

  return (
    <motion.div className="flex flex-col gap-6 px-4 py-8">
      {loading && <QuestionLoading />}
      {!loading && questions.length === 0
        ? profile && (
            <QuestionZeroState
              profile={profile}
              heading="your inbox is empty."
              subheading="Share the link below to start receiving questions!"
            />
          )
        : questions.map((question) => (
            <QuestionCard
              key={question.id}
              question={question}
              handleReadMore={(id) => handleViewAskedQuestion(question)}
            />
          ))}
    </motion.div>
  );
};

export default AskedQuestions;
