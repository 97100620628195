// templates/component/QuestionUrlStep.tsx

import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import InputField from '../../../../../components/InputField';
import { formatUrl } from '../../../../../helpers/misc';
import useMyQuestionUrl from '../../../../../hooks/useMyQuestionUrl';
import { useQuestionService } from '../../../../../services/question.service';
import { useAppSelector } from '../../../../../store/store';

interface QuestionUrlStepProps {
  setValid: (valid: boolean) => void;
}

const QuestionUrlStep: React.FC<QuestionUrlStepProps> = ({ setValid }) => {
  // hooks
  const {
    register,
    formState: { errors, isDirty },
    control,
    setValue,
  } = useFormContext();
  const url = useWatch({ control, name: 'url' });
  const formattedUrl = useWatch({ control, name: 'formattedUrl' });
  const profile = useAppSelector((state) => state.auth.profile);
  const { checkIfQuestionUrlExists } = useQuestionService();
  const { myQuestionUrl } = useMyQuestionUrl();

  // refs

  // effects
  useEffect(() => {
    if (errors.url) {
      setValid(false);
    } else if (!url) {
      setValid(false);
    } else if (url.length === 0) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [url]);

  useEffect(() => {
    // only want to fire this when component mounts, otherwise we want to default to the user inputs.
    if (!formattedUrl) {
      generateDefaultUrl();
    } else {
      setValue('formattedUrl', formatUrl(url));
    }
  }, [url]);

  // methods
  // this can be called recursively to get a unique url.  Each time we add a number to the end of the url.
  const generateDefaultUrl = async (duplicateCheck = 0): Promise<undefined> => {
    // this should always be true, but TS wants us to be careful
    if (!profile) return;

    // format url and replace spaces with dashes
    let urlToCheck = formatUrl(url);
    if (duplicateCheck > 0) {
      urlToCheck = `${urlToCheck}-${duplicateCheck}`;
    }
    // check if url is already in use
    const exists = await checkIfQuestionUrlExists(urlToCheck, profile.id);
    if (exists) {
      // calling recursively to get a unique url
      return generateDefaultUrl(duplicateCheck + 1);
    } else {
      setValue('formattedUrl', urlToCheck);
    }
  };

  return (
    <div>
      <h2>Create a unique URL for your question:</h2>
      <form className="flex flex-col gap-4">
        <InputField
          className="w-full"
          type="text"
          label="URL"
          name="url"
          register={register}
          error={errors.url as FieldError}
        />
      </form>
      {profile && (
        <div className="pt-8">
          <h2>When users navigate to this url they will see your question:</h2>
          <p className="pt-4 text-xl">{myQuestionUrl(formattedUrl)}</p>
        </div>
      )}
    </div>
  );
};

export default QuestionUrlStep;
