// templates/component/QuestionDetailsStep.tsx

import { IonProgressBar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import InputField from '../../../../../components/InputField';
import { formatUrl } from '../../../../../helpers/misc';

interface QuestionDetailsStepProps {
  setValid: (valid: boolean) => void;
}

const QuestionDetailsStep: React.FC<QuestionDetailsStepProps> = ({
  setValid,
}) => {
  // hooks
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const subject = useWatch({ control, name: 'subject' });
  const question = useWatch({ control, name: 'question' });

  // state
  const maxLength = 500;
  const [charCount, setCharCount] = useState(0);

  // refs

  // effects
  useEffect(() => {
    if (errors.subject || errors.question) {
      setValid(false);
    } else if (!subject || !question) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [subject, question]);

  useEffect(() => {
    if (question) {
      setCharCount(question.length);
    } else {
      setCharCount(0);
    }
  }, [question]);

  useEffect(() => {
    if (subject) {
      setValue('url', formatUrl(subject));
    }
  }, [subject]);

  // methods
  return (
    <div>
      <h2>Post a question that other people can answer:</h2>
      <form className="flex flex-col gap-4">
        <div>
          <InputField
            className="w-full"
            type="text"
            label="Subject"
            name="subject"
            register={register}
            error={errors.subject as FieldError}
          />
        </div>
        <div>
          <div className="relative mt-6 rounded-md">
            <textarea
              className={`border-1 h-96 w-full rounded-t-md border-gray-300 focus:ring-0 sm:text-sm md:resize-none ${
                charCount < maxLength - 200 && 'resize-none'
              }`}
              id="question-input"
              {...register('question', {
                required: true,
                maxLength: {
                  value: maxLength,
                  message: `Your question must be less than ${maxLength} characters`,
                },
              })}
              placeholder="Write your question here..."
              maxLength={maxLength}
            />
            <label
              htmlFor="question-input"
              className={`absolute -top-3 left-2 bg-blue-50 px-1 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3 peer-focus:bg-white peer-focus:text-sm peer-focus:text-gray-600`}
            >
              Question
            </label>
            <div
              className={`absolute bottom-2 right-1 left-auto top-auto text-xs ${
                charCount > maxLength - 30
                  ? charCount === maxLength
                    ? 'text-red-600'
                    : 'text-yellow-500'
                  : 'text-gray-400'
              } ${charCount < maxLength - 200 && 'hidden'}`}
            >
              <p>
                {charCount}/{maxLength}
              </p>
            </div>
            <IonProgressBar
              value={charCount / maxLength}
              className="absolute bottom-1 top-auto left-0 right-0 rounded-md"
            ></IonProgressBar>
            {errors.question && (
              <p className=" absolute -bottom-3 left-2 ml-2 bg-white px-1 text-sm text-red-600">
                {errors.question?.message}
              </p>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default QuestionDetailsStep;
